import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  getFirestore,
} from 'firebase/firestore';
import { spliceIntoChunks } from '@/Global/Utils';
import firebaseApp from '@/services/firebase';
import { Order } from '@/DTOs';
import { appendError } from '@/services/storage';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/** Places an order on an user.
 * @param {Object} orderData
 */
export const placeOrder = (userId, orderData) => {
  const orderObj = new Order(orderData);
  return setDoc(
    doc(db, `users/${userId}/orders`, orderData.orderNumber),
    orderObj.getObject
  );
};

/** Places multiple orders
 * @param {string} userId
 * @param {Object[]} ordersSingleArray - Must be an array of 'order' objects
 */
export const placeOrders = async (userId, ordersSingleArray) => {
  const ordersMultipleArray = spliceIntoChunks(ordersSingleArray, 200);
  const promisses = ordersMultipleArray.map((orders) =>
    runTransaction(db, async (t) => {
      const arrPromise = orders.map((order) => {
        const { orderNumber } = order;
        const ordersRef = doc(db, `users/${userId}/orders`, orderNumber);

        const orderObj = new Order(order);
        return t.set(ordersRef, orderObj.getObject);
      });
      return Promise.all(arrPromise)
        .then(() => Promise.resolve())
        .catch((err) => {
          appendError({
            path: 'src/services/dbFunctions/Orders/index.js',
            message: err.message,
          });
          Promise.reject(err);
        });
    })
  );
  return Promise.all(promisses)
    .then(() => Promise.resolve())
    .catch((err) => {
      appendError({
        path: 'src/services/dbFunctions/Orders/index.js',
        message: err.message,
      });
      Promise.reject(err);
      console.log(err);
    });
};

/** Get order data
 * @param {string} userId
 * @param {string} orderId
 */
export const getOrderData = (userId, orderId) =>
  getDoc(doc(db, `users/${userId}/orders`, orderId)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), orderNumber: docSnap.id };
    }
    return null;
  });

/** Get all user's orders
 * @param {string} userId
 */
export const getAllUserOrders = (userId) =>
  getDocs(collection(db, `users/${userId}/orders`)).then((querySnapshot) =>
    querySnapshot.map((docSnap) => ({
      ...docSnap.data(),
      orderNumber: docSnap.id,
    }))
  );

/** Get paginated user's orders. Returns an object with an array 'orders'
 * with the orders data, and a parameter 'nextQuery', which can be
 * passed as a parameter to the next call of this function, to give the
 * next page
 * @param {string} userId
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page.
 */
export const getPaginatedUserOrders = (
  userId,
  orderByToMake,
  queryToMake = query(
    collection(db, `users/${userId}/orders`),
    orderBy(orderByToMake, 'desc'),
    orderBy('customerName'),
    limit(10)
  )
) => makeQuery(queryToMake, 'orderNumber').then((result) => result);

/** Change an order status
 * @param {string} userId
 * @param {string} orderId
 * @param {string} newStatus
 */
export const setOrderStatus = (userId, orderId, newStatus) =>
  updateDoc(doc(db, `users/${userId}/orders`, orderId), { status: newStatus });

/** Deletes an order
 * @param {string} userId
 * @param {string} orderId
 */
export const deleteOrder = (userId, orderId) =>
  deleteDoc(doc(db, `users/${userId}/orders`, orderId));
