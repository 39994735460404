import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Second instance of firebase for creating new users without sign in
export const secondaryApp = initializeApp(firebaseConfig, 'Secondary');

export default firebaseApp;
