import Resizer from 'react-image-file-resizer';

// Compresses an image to a max resolution and quality
export const compressImage = (file) => {
  const MAX_WIDTH = 1000;
  const MAX_HEIGHT = 1000;
  const FORMAT = 'JPEG';
  const QUALITY = 80;
  const ROTATION = 0; // No rotation
  const OUTPUT = 'file'; // Can be either base64, blob or file. (Default type is base64)

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      MAX_WIDTH,
      MAX_HEIGHT,
      FORMAT,
      QUALITY,
      ROTATION,
      (uri) => {
        resolve(uri);
      },
      OUTPUT
    );
  });
};
