import { useState, useRef, useEffect } from 'react';

import { IconButton } from '@material-ui/core';

import { HeaderMenu, HeaderMenuItem, Container } from './ActionBox_style';

const ActionBox = ({ items, IconOpen, IconClose, $header, $oneItem }) => {
  const wrapperRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onClick = (fn) => {
    toggleExpanded();
    fn();
  };

  return (
    <Container>
      <IconButton data-testid="toggle-button" onClick={toggleExpanded}>
        {isExpanded ? IconOpen() : IconClose ? IconClose() : IconOpen()}
      </IconButton>
      {isExpanded && (
        <HeaderMenu ref={wrapperRef} $header={$header} $oneItem={$oneItem}>
          {items.map((item) => (
            <HeaderMenuItem
              $item={item.label}
              key={item.label}
              disabled={item.disabled}
              data-testid="item-button"
              onClick={() => (item.disabled ? {} : onClick(item.onClick))}
            >
              {item.icon()}
              <div>{item.label}</div>
            </HeaderMenuItem>
          ))}
        </HeaderMenu>
      )}
    </Container>
  );
};

export default ActionBox;
