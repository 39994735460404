import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import App from './firebase';
import { compressImage } from './utils';

const storage = getStorage(App);

const errorLog = ref(storage, 'errorLogFrontEnd.txt');

/** Appends an string to the 'errorLogFrontEnd.txt' file
 * @param {String} message - String to add
 */
export const appendString = async (message) => {
  getDownloadURL(errorLog).then((url) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = async () => {
      const res = xhr.response;
      const text = await res.text();
      // console.log(text);

      const blob = new Blob([message, text], { type: 'text/plain' });
      return uploadBytes(errorLog, blob);
    };
    xhr.open('GET', url);
    xhr.send();
  });
};

/** Transforms an errorObj to a text string and adds it to
 *  the 'errorLogFrontEnd.txt' file.
 * @param {Object} errorObj
 */
export const appendError = (errorObj) => {
  const { path, message } = errorObj;

  const timestamp = new Date();
  const zeroPad = (num, places) => String(num).padStart(places, '0');
  const year = timestamp.getFullYear();
  const month = timestamp.getMonth();
  const day = timestamp.getDate();
  const hour = zeroPad(timestamp.getHours(), 2);
  const minutes = zeroPad(timestamp.getMinutes(), 2);
  const seconds = zeroPad(timestamp.getSeconds(), 2);

  const errorTxt = `Error: ${message}\nOn the file ${path}, at ${year}-${
    month + 1
  }-${day} ${hour}:${minutes}:${seconds}.\n\n`;
  appendString(errorTxt);
};

/**
 * Uploads an image and returns download URL, in case of error, returns the error.
 * @param {*} userId - franchisee Id
 * @param {*} imageFile - name of the image file
 * @param {*} tipId - tip Id
 * @returns - returns the image name
 */
export const uploadImage = async (userId, imageFile, tipId) => {
  const compressedImage = await compressImage(imageFile);
  const imageRef = ref(
    storage,
    `images/${userId}/${tipId}/${compressedImage.name}`
  );
  try {
    await uploadBytes(imageRef, compressedImage);
  } catch (error) {
    return error;
  }
  return compressedImage.name;
};

/**
 * Gets the image
 * @param {*} userId - franchisee Id
 * @param {*} imageFile - name of the image file
 * @param {*} tipId - tip Id
 * @returns - returns the image url
 */
export const getImage = async (userId, imageName, tipId) => {
  const imageUrl = await getDownloadURL(
    ref(storage, `images/${userId}/${tipId}/${imageName}`)
  );
  return imageUrl;
};

/**
 * Delete the image
 * @param {*} userId - franchisee Id
 * @param {*} imgaFile - name of the image file
 * @param {*} tipId - tip Id
 */
export const deleteImage = async (userId, imgaName, tipId) => {
  const imageToDeleteRef = ref(
    storage,
    `images/${userId}/${tipId}/${imgaName}`
  );
  deleteObject(imageToDeleteRef)
    .then(() => {})
    .catch((error) => {
      throw error;
    });
};

/**
 * Uploads the visit images
 * @param {string} userId
 * @param {string} visitId
 * @param {Array<File>} imageFileArray
 * @returns - a `Promise` that when solved uploads all the images
 */
export const uploadVisitImages = async (userId, visitId, imageFileArray) =>
  Promise.all(
    imageFileArray.map(async (imageFile) => {
      const compressedImage = await compressImage(imageFile);
      // Uses uuidv4 to create a unique filename
      const imageRef = ref(
        storage,
        `images/${userId}/visits/${visitId}/${uuidv4()}`
      );
      await uploadBytes(imageRef, compressedImage);
    })
  );

/**
 * Gets all images from a visit
 * @param {string} userId
 * @param {string} visitId
 * @returns - a `Promise`that when solved returns an array with the URL
 * of all the images.
 */
export const getVisitImages = async (userId, visitId) => {
  const visitRef = ref(storage, `images/${userId}/visits/${visitId}`);
  const res = await listAll(visitRef);
  return Promise.all(
    res.items.map(async (imageFile) =>
      getDownloadURL(ref(storage, imageFile.fullPath))
    )
  );
};

/**
 * Deletes all images from a visit
 * @param {string} userId
 * @param {string} visitId
 * @returns - a `Promise`that when solved deletes all images from a visit
 */
export const deleteVisitImages = async (userId, visitId) => {
  const visitRef = ref(storage, `images/${userId}/visits/${visitId}`);
  const res = await listAll(visitRef);
  return Promise.all(
    res.items.map(async (imageFile) =>
      deleteObject(ref(storage, imageFile.fullPath))
    )
  );
};
