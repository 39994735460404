import styled from 'styled-components';
import {
  Button,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
} from '@material-ui/core';
import { GlobalFontStyle } from '@/Global/GlobalStyles';

export const DialogContent = styled(MuiDialogContent)`
  ${({ isMd }) => isMd && `
  width: 25vw;
  `}

  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    margin-top: 73px;
    margin-bottom: 38px;
    width: 45%;
  }
`;

export const DialogContentTitle = styled(MuiDialogContentText)`
  color: #333333 !important;
  font: ${GlobalFontStyle.H2.font} !important;
`;

export const DialogContentText = styled(MuiDialogContentText)`
  color: #94a2b3;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font: ${GlobalFontStyle.H4.font} !important;
`;

export const StyledButton = styled(Button)`
  height: 44px;
  margin-top: 24px !important;
  margin-bottom: 44px !important;
  width: 80% !important;
  box-shadow: none !important;

  span {
    font: ${GlobalFontStyle.H4.font} !important;
  }
`;
