class Tip {
  constructor(tipObject) {
    const {
      createdDate,
      franchiseId,
      imageRef,
      optionalLink,
      tipsStatus,
      textContent,
      tipId,
      title,
      commodities,
      states,
      dateToSend,
      timeToSend,
    } = tipObject;

    // Required fields
    if (!createdDate || !franchiseId || !tipsStatus) {
      throw new Error('Undefined createdDate, franchiseId or tipsStatus');
    }
    this.tipsStatus = tipsStatus;
    this.createdDate = createdDate;
    this.franchiseId = franchiseId;

    // Optional fields
    this.commodities = commodities || null;
    this.states = states || null;
    this.imageRef = imageRef || null;
    this.optionalLink = optionalLink || null;
    this.textContent = textContent || null;
    this.tipId = tipId || null;
    this.title = title || null;
    this.dateToSend = dateToSend || null;
    this.timeToSend = timeToSend || null;
  }

  get getObject() {
    return {
      commodities: this.commodities,
      states: this.states,
      createdDate: this.createdDate,
      franchiseId: this.franchiseId,
      imageRef: this.imageRef,
      optionalLink: this.optionalLink,
      textContent: this.textContent,
      tipsStatus: this.tipsStatus,
      title: this.title,
      tipId: this.tipId,
      dateToSend: this.dateToSend,
      timeToSend: this.timeToSend,
    };
  }
}

export default Tip;
