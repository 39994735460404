import { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { useSelector } from '@/Redux';
// Global Components
import AdminHeader from '@/Global/Modules/Navigation/Components/AdminHeader';
import UserHeader from '@/Global/Modules/Navigation/Components/UserHeader';
import SideMenu from '@/Global/Modules/Navigation/Components/SideMenu';
// Style
import {
  ContainerFlex,
  ContainerMain,
  ContentColumn,
  LoadingContainer,
} from './Navigation_style';

// Global Modules
const LoginPage = lazy(() => import('@/Global/Modules/Login/Page'));
const RecoverPasswordPage = lazy(() =>
  import('@/Global/Modules/RecoverPassword/Page')
);

// Modules
const CreateVisitSolicitationPage = lazy(() =>
  import('@/Modules/CreateVisitSolicitation/Page')
);
const FranchiseePage = lazy(() => import('@/Modules/Franchisee/Page'));
const FranchiseeCreateVisit = lazy(() =>
  import('@/Modules/FranchiseeCreateVisit/Page')
);
const CustomersPage = lazy(() => import('@/Modules/Customers/Page'));
const OrdersPage = lazy(() => import('@/Modules/Orders/Page'));
const MyProfilePage = lazy(() => import('@/Modules/MyProfile/Page'));
const TipsPage = lazy(() => import('@/Modules/Tips/Page'));
const CreateTipsPage = lazy(() => import('@/Modules/CreateTips/Page'));
const SentTipsPage = lazy(() => import('@/Modules/SentTips/Page'));
const VisitsPage = lazy(() => import('@/Modules/Visits/Page'));
const RtvUsersPage = lazy(() => import('@/Modules/RtvUsers/Page'));
const CreateRtvPage = lazy(() => import('@/Modules/CreateRtv/Page'));
const CreateRtvPageFranchisee = lazy(() =>
  import('@/Modules/CreateRtvFranchisee/Page')
);
const VisitSolicitationPage = lazy(() =>
  import('@/Modules/VisitSolicitation/Page')
);
// Modules Components
const UserRegistration = lazy(() =>
  import('@/Modules/Franchisee/Components/UserRegistration')
);
const EditUser = lazy(() => import('@/Modules/Franchisee/Components/EditUser'));

const PasswordForm = lazy(() =>
  import('@/Modules/MyProfile/Components/PasswordForm')
);

const Navigation = () => {
  const md = useMediaQuery('(min-width:1024px)');
  const { user, loadingAuth } = useSelector(
    (state) => state.Authentication.AuthReducer
  );
  const isLoggedIn = !!user;
  const { isAdmin } = user?.userData || { isAdmin: false };
  const { isRTV } = user?.userData || { isRTV: false };

  const routes = (
    <>
      <Switch>
        {isAdmin && (
          <Route path="/franchisee" exact component={FranchiseePage} />
        )}
        {isAdmin && <Route path="/unidentifiednumbers" exact />}
        {isAdmin && (
          <Route path="/userregister" exact component={UserRegistration} />
        )}
        {isAdmin && <Route path="/edituser" exact component={EditUser} />}
        {!isRTV && <Route path="/customers" exact component={CustomersPage} />}
        {!isRTV && <Route path="/orders" exact component={OrdersPage} />}
        <Route path="/myprofile" exact component={MyProfilePage} />
        <Route path="/profile-password" exact component={PasswordForm} />
        {!isRTV && <Route path="/tips" exact component={TipsPage} />}
        {!isRTV && (
          <Route path="/create-tips" exact component={CreateTipsPage} />
        )}
        {!isRTV && (
          <Route path="/create-tips/:id" exact component={CreateTipsPage} />
        )}
        {!isRTV && (
          <Route path="/view-sent-tips/:id" exact component={SentTipsPage} />
        )}
        {!isRTV && <Route path="/rtv-users" exact component={RtvUsersPage} />}
        {!isRTV && <Route path="/create-rtv" exact component={CreateRtvPage} />}
        {!isRTV && (
          <Route path="/create-rtv/:id" exact component={CreateRtvPage} />
        )}
        
        {!isRTV && (
          <Route
            path="/franchisee-visit"
            exact
            component={FranchiseeCreateVisit}
          />
        )}

        {!isRTV && (
          <Route
            path="/franchisee-create-rtv/"
            exact
            component={CreateRtvPageFranchisee}
          />
        )}
        {!isRTV && (
          <Route
            path="/franchisee-create-rtv/:id"
            exact
            component={CreateRtvPageFranchisee}
          />
        )}

        <Route path="/visits" exact component={VisitsPage} />
        <Route
          path="/visit-solicitation/:id"
          exact
          component={VisitSolicitationPage}
        />
        <Route
          path="/create-visit-solicitation"
          exact
          component={CreateVisitSolicitationPage}
        />
        {!isRTV ? (
          <Redirect from="/" to="/customers" />
        ) : (
          <Redirect from="/" to="/visits" />
        )}
      </Switch>
    </>
  );

  return (
    <Router>
      <Suspense
        fallback={
          <LoadingContainer>
            <CircularProgress size={100} />
          </LoadingContainer>
        }
      >
        <ContainerFlex>
          {isLoggedIn && isAdmin && !loadingAuth && <SideMenu />}
          {isLoggedIn ? (
            loadingAuth ? (
              <LoadingContainer>
                <CircularProgress size={100} />
              </LoadingContainer>
            ) : (
              <ContentColumn fullWidth={isAdmin}>
                {isAdmin ? <AdminHeader /> : <UserHeader md={md} />}
                <ContainerMain>{routes}</ContainerMain>
              </ContentColumn>
            )
          ) : (
            <>
              <Switch>
                <Route
                  path="/recover-password"
                  exact
                  component={RecoverPasswordPage}
                />
                <Route path="/login" exact component={LoginPage} />
                <Redirect from="/" to="/login" />
              </Switch>
            </>
          )}
        </ContainerFlex>
      </Suspense>
    </Router>
  );
};

export default Navigation;
