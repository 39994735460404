export const phoneNumberMasks = (phoneNumber) => {
  if (!phoneNumber) return '';
  let result = phoneNumber.replace(/\D/g, '');
  const pattern =
    result.length === 11
      ? /(\d{2})(\d{1})(\d{4})(\d{4})/
      : /(\d{2})(\d{4})(\d{4})/;
  result = result.match(pattern);
  return result.length === 5
    ? `(${result[1]}) ${result[2]}.${result[3]}-${result[4]}`
    : `(${result[1]}) ${result[2]}-${result[3]}`;
};
