import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  limit,
  orderBy,
  query,
  runTransaction,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { appendError } from '@/services/storage';
import firebaseApp from '../../firebase';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/** Adds a message group, which is a collection of messages that
 * have the same meaning.
 * @param {Object} messageGroup
 * @param {Number} messageGroup.count - Must pass this parameter with the
 * value equal to zero, later on it will be incremented each time a message
 * is added.
 */
export const addMessageGroup = (messageGroup) =>
  addDoc(collection(db, 'messageGroup'), messageGroup);

/** Adds a single message to a message group.
 * @param {string} messageGroupId
 * @param {Object} message
 */
export const addMessage = (messageGroupId, message) => {
  const messageGroupRef = doc(db, 'messageGroup', messageGroupId);

  return runTransaction(db, async (t) => {
    const docSnap = await t.get(messageGroupRef);
    const newCount = docSnap.data().count + 1;
    const messageRef = doc(
      collection(cb, `messageGroup/${messageGroupId}/messages`)
    );
    t.set(messageRef, message);
    t.update(messageGroupRef, { count: newCount });
  });
};

/** Adds multiple messages
 * @param {string} messageGroupId
 * @param {Object[]} messages - Must be an array of 'message' objects
 */
export const addMessages = (messageGroupId, messages) =>
  runTransaction(db, (t) => {
    messages.forEach((message) => {
      const messageRef = doc(
        collection(db, `messageGroups/${messageGroupId}/messages`)
      );
      t.set(messageRef, message);
    });
  });

/** Deletes a message
 * @param {string} messageGroupId
 * @param {string} messageId
 */
export const deleteMessage = (messageGroupId, messageId) =>
  db.runTransaction(async (t) => {
    const messageRef = doc(
      db,
      `messageGroups/${messageGroupId}/messages`,
      messageId
    );
    const messageDoc = await t.get(messageRef);
    if (messageDoc.exists()) {
      t.delete(messageRef);
    } else {
      throw new Error('Message does not exists');
    }
  });

/** Deletes only the message group document
 * @param {string} messageGroupId
 */
const deleteMessageGroupDocument = (messageGroupId) =>
  deleteDoc(doc(db, 'messagfeGroups', messageGroupId));

/** Deletes a message group entirely
 * @param {string} messageGroupId
 */
export async function deleteMessageGroup(messageGroupId) {
  // Cloud function that deletes the 'messages' collection inside
  // the messageGroup document.
  const functions = getFunctions(firebaseApp);
  const deleteFn = httpsCallable(functions, 'recursiveDeleteMessages');

  try {
    await deleteFn({ messageGroupId });
    await deleteMessageGroupDocument(messageGroupId);
  } catch (err) {
    appendError({
      path: 'src/services/dbFunctions/Messages/index.js',
      message: err.message,
    });
    throw new Error('Failed to delete messageGroup');
  }
}

/** Get paginated message groups. Returns an object with an array of the
 * message groups data and an parameter 'nextQuery', which can be passed as a
 * parameter to the next call of this function, to give the next page.
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedMessageGroups = (
  orderByToMake,
  queryToMake = query(
    collection(db, 'messageGroups'),
    orderBy(orderByToMake, 'desc'),
    limit(10)
  )
) => makeQuery(queryToMake, 'messageGroupId').then((result) => result);

/** Get paginated messages of a message group. Returns an object with
 * an array of the messages data and an parameter 'nextQuery',
 * which can be passed as a parameter to the next call of this function,
 * to give the next page.
 * @param {string} messageGroupId - Id of the messageGroup which the messages
 * will be taken from.
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedMessages = (
  messageGroupId,
  orderByToMake,
  queryToMake = query(
    collection(db, `messageGroups/${messageGroupId}/messages`),
    orderBy(orderByToMake),
    limit(10)
  )
) => makeQuery(queryToMake, 'messageId').then((result) => result);

/** Gets the data of a single message group
 * @param {string} messageGroupId
 */
export const getMessageGroup = (messageGroupId) =>
  getDoc(doc(db, 'messageGroups', messageGroupId)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), messageGroupId: docSnap.id };
    }
    return null;
  });

/** Gets the data of a single message
 * @param {string} messageGroupId
 * @param {string} messageId
 */
export const getMessage = (messageGroupId, messageId) =>
  getDoc(doc(db, `messageGroups/${messageGroupId}/messages`, messageId)).then(
    (docSnap) => {
      if (docSnap.exists()) {
        return { ...docSnap.data(), messageId: docSnap.id };
      }
      return null;
    }
  );

/** Returns the unidentified numbers with pagination. Returns an object with
 * an array of the numbers data and an parameter 'nextQuery',
 * which can be passed as a parameter to the next call of this function,
 * to give the next page.
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedUnidentifiedNumbers = (
  orderByToMake,
  queryToMake = query(
    collection(db, 'unidentifiedNumbers'),
    orderBy(orderByToMake),
    limit(10)
  )
) => makeQuery(queryToMake, 'unidentifiedNumberId').then((result) => result);

/** Delete an unidentified number
 * @param {string} deleteUnindentifiedId
 */
export const deleteUnidentifiedNumbers = (deleteUnidentifiedId) =>
  deleteDoc(doc(db, 'unidentifiedNumbers', deleteUnidentifiedId));
