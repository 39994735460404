import {
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import firebaseApp from '@/services/firebase';
import * as customersActionsTypes from '@/Modules/Customers/Redux/Types';
import * as franchiseeActionsTypes from '@/Modules/Franchisee/Redux/Types';
import * as ordersActionsTypes from '@/Modules/Orders/Redux/Types';
import { isUserActive } from '../../../../../services/cloudFunctions';
import * as actionTypes from '../Types/AuthTypes';

/** Sign In Action */
export const signInAction = ({ email, password }, rememberMe) => async (
  dispatch
) => {
  const auth = getAuth(firebaseApp);

  return setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  ).then(async () => {
    try {
      // Checks if user is active
      const isActive = await isUserActive(email);
      if (isActive === false) {
        const error = new Error(
          'Usuário desativado, entre em contato com um admistrador'
        );
        return Promise.reject(error);
      }
      await signInWithEmailAndPassword(auth, email, password);
      dispatch({
        type: actionTypes.AUTH_SIGNIN_SUCCESS,
      });
      return Promise.resolve(true);
    } catch (err) {
      dispatch({
        type: actionTypes.AUTH_SIGNIN_ERROR,
        message: 'Erro no login.',
      });
      let message = null;
      switch (err.code) {
        case 'auth/too-many-requests':
          message =
            'Você excedeu o limite de tentativas, tente novamente mais tarde.';
          break;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          message = 'Email ou Senha inválidos, tente novamente.';
          break;
        default:
          message = 'Erro ao realizar o login.';
      }
      // Erro 16
      const error = new Error(message);

      return Promise.reject(error);
    }
  });
};

/** Sign Out Action */
export const signOutAction = () => (dispatch) => {
  const auth = getAuth(firebaseApp);

  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        dispatch({ type: actionTypes.AUTH_SIGNOUT_SUCCESS });
        dispatch({
          type: customersActionsTypes.CUSTOMERS_TABLE_SET_INITIAL_STATE,
        });
        dispatch({
          type: franchiseeActionsTypes.FRANCHISEE_TABLE_SET_INITIAL_STATE,
        });
        dispatch({ type: ordersActionsTypes.ORDERS_TABLE_SET_INITIAL_STATE });
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** Just set user state, without performing firebase login */
export const setUser = (user, loadingState) => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_SET_USER_STATE,
    user,
    loadingState,
  });
};

/** Set the loading state (when auth process is loading) */
export const setLoading = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_SET_LOADING,
    value,
  });
};

/** Clean signIn and signUp errors */
export const cleanErrors = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_CLEAN_UP_ERRORS,
  });
};

/** Recovery */
export const recoverPassword = (email) => async (dispatch) => {
  const auth = getAuth(firebaseApp);
  sendPasswordResetEmail(auth, email).then(() => {
    dispatch({
      type: actionTypes.AUTH_RECOVER_PASSWORD,
      email,
    });
  });
};
