class Customer {
  constructor(customerObject) {
    const {
      customerId,
      system,
      uploadDate,
      userId,
      customerName,
    } = customerObject;

    if (!customerId || !system || !userId || !customerName)
      throw new Error('Undefined customerId, system, userId or customerName');

    this.customerId = customerId;
    this.system = system;
    this.userId = userId;
    this.customerName = customerName;
    this.uploadDate = uploadDate || new Date();
  }

  get getObject() {
    return {
      customerId: this.customerId,
      system: this.system,
      uploadDate: this.uploadDate,
      userId: this.userId,
      customerName: this.customerName,
    };
  }
}

export default Customer;
