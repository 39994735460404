import {
  addDoc,
  collection,
  deleteDoc,
  getDoc,
  getFirestore,
  updateDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import firebaseApp from '@/services/firebase';
import Product from '@/DTOs/Product';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/**
 * Creates a product document
 * @param {object} productData
 * @returns - a `Promise` that when solved creates the document
 */
export const createProduct = (productData) => {
  const productObject = new Product(productData);
  return addDoc(collection(db, 'products'), productObject.getObject);
};

/**
 * Gets the product
 * @param {string} productId
 * @returns - a `Promise` that when solved returns an object with the
 * document data.
 */
export const getProduct = (productId) =>
  getDoc(doc(db, `products/${productId}`)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), productId: docSnap.id };
    }
    return null;
  });

/**
 * Updates the product data
 * @param {string} productId
 * @param {object} productData
 * @returns - a `Promise` that when solved, updates the document
 */
export const updateProduct = (productId, productData) =>
  updateDoc(doc(db, `products/${productId}`), productData);

/**
 * Deletes a product
 * @param {string} productId
 * @returns - a `Promise` that when solved, deletes the document
 */
export const deleteProduct = (productId) =>
  deleteDoc(doc(db, `products/${productId}`));

/**
 * Lists All Products
 */
export const getAllProducts = () =>
  getDocs(collection(db, `products`)).then((querySnapshot) =>
    querySnapshot.docs.map((docSnap) => ({
      ...docSnap.data(),
      productId: docSnap.id,
    }))
  );

/** Lists All Groups */
export const getAllGroups = async () => {
  const allProducts = await getAllProducts();
  const allGroups = allProducts.map((product) => product.groupName);
  return [...new Set(allGroups)];
};

/**
 * Lists All Products From a Group
 * @param {string} groupName
 */
export const getAllProductsFromGroup = async (groupName) => {
  const q = query(
    collection(db, 'products'),
    where('groupName', '==', groupName)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnap) => ({
    ...docSnap.data(),
    productId: docSnap.id,
  }));
};
