import styled from 'styled-components';
import {
  GlobalFontStyle,
  GlobalColors,
  BorderStyle,
} from '@/Global/GlobalStyles';

export const HeaderMenu = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: 100px;
  height: ${({ $oneItem }) => ($oneItem ? '37px' : '75px')};
  background-color: ${GlobalColors.SupportColors.pureWhite};
  border-radius: ${BorderStyle.Radius.small};
  border: ${BorderStyle.Density.small} solid
    ${GlobalColors.SupportColors.grayMedium};
  top: ${({ $header }) => ($header ? '16px' : '4px')};
  right: 0;
  box-shadow: none;
  font: ${GlobalFontStyle.H6.font};
`;
export const HeaderMenuItem = styled.div`
  display: flex;
  color: ${({ disabled }) =>
    disabled
      ? GlobalColors.SupportColors.grayMedium
      : GlobalColors.SupportColors.blackLight};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  justify-content: space-evenly;
  align-items: center;
  div {
    width: ${({ $item }) => ($item !== 'Visualizar' ? '50px' : '58px')};
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;
