import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseApp from './services/firebase';
import GlobalTheme from './Global/GlobalTheme';
import Navigation from './Global/Modules/Navigation/Page';
import { getUserData} from './services/dbFunctions';
import * as actions from './Global/Modules/Login/Redux/Actions';

const App = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const auth = getAuth(firebaseApp);
    // Set loading state in authReducer to true (after finished
    // auth process, automatically setting to false.)
    dispatch(actions.setLoading(true));
    const unsubscribe = onAuthStateChanged(auth, async (userFirebase) => {
      if (userFirebase) {
        // User is signed in
        getUserData(userFirebase.uid).then((userData) => {
          dispatch(actions.setUser({ userFirebase, userData }, false));
        });
      } else {
        // No user is signed in
        dispatch(actions.setUser(null, true));
      }
    });

    return unsubscribe;
  }, []);

  return (
    <GlobalTheme>
      <Navigation />
    </GlobalTheme>
  );
};

export default App;
