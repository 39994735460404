import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Arrow from '@/Assets/drawer/arrow.svg';
import { useSelector } from '@/Redux';
import AvatarMenu from '@/Global/Components/AvatarMenu';
import GoplanLogo from '@/Assets/Goplan/goplan-logo.svg';
import Drawer from '@/Global/Components/Drawer';
import { DivLogo, Header, Menu, MenuItem } from './UserHeader_style';
import { version } from '../../../../../../package.json';

const UserHeader = ({md}) => {
  const location = useLocation();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('/customers');
  const [counter, setCounter] = useState(0);
  const { user } = useSelector((state) => state.Authentication.AuthReducer);
  const { isRTV } = user?.userData || { isRTV: false };
  const path = window.location.pathname;

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const menuItemClick = (pathname) => {
    if (pathname !== activeItem) {
      history.push(pathname);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(0);
    }, 1000);

    if (counter === 10) {
      // VERSION
      // eslint-disable-next-line
      alert(
        `Versão: ${version}\nAmbiente: ${process.env.REACT_APP_ENV || 'local'}`
      );
    }
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  return (
    <Header>
      {md ? (
        <>
          <Menu>
            {/* eslint-disable-next-line */}
            <img
              src={GoplanLogo}
              alt="Goplan"
              onClick={() => {
                setCounter((oldState) => oldState + 1);
              }}
            />

            {/* Orders menu temporarily disabled because of LGPD issues. */}
            {/* <MenuItem
          active={activeItem === '/orders'}
          onClick={() => menuItemClick('/orders')}
        >
          Pedidos
        </MenuItem> */}
            {!isRTV && (
              <MenuItem
                active={activeItem === '/customers'}
                onClick={() => menuItemClick('/customers')}
              >
                Clientes
              </MenuItem>
            )}
            {!isRTV && (
              <MenuItem
                active={activeItem === '/tips'}
                onClick={() => menuItemClick('/tips')}
              >
                Dicas
              </MenuItem>
            )}
            <MenuItem
              active={activeItem === '/visits'}
              onClick={() => menuItemClick('/visits')}
            >
              Visitas
            </MenuItem>
            {!isRTV && (
              <MenuItem
                active={activeItem === '/rtv-users'}
                onClick={() => menuItemClick('/rtv-users')}
              >
                RTV
              </MenuItem>
            )}
          </Menu>
          <AvatarMenu />
        </>
      ) : (
        <Menu style={{ justifyContent: 'space-around' }}>
          {path === '/visits' ? (
            <div>
              <Drawer />
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              onClick={() => {
                history.push('/visits');
              }}
            >
              <img src={Arrow} alt="back" />
            </div>
          )}
          {/* eslint-disable-next-line */}
          <DivLogo
            onClick={() => {
              setCounter((oldState) => oldState + 1);
            }}
          >
            <img
              style={{ alignContent: 'center' }}
              src={GoplanLogo}
              alt="Goplan"
            />
          </DivLogo>
        </Menu>
      )}
    </Header>
  );
};

export default UserHeader;
