import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
} from '@material-ui/icons';

import { useSelector } from '@/Redux';

import * as actions from '@/Global/Modules/Login/Redux/Actions';
import TwoActionsDialog from '../TwoActionsDialog';
import {
  AvatarContainer,
  NameTitle,
  ExpandMoreIcon,
  ExpandLessIcon,
} from './AvatarMenu_style';

import ActionBox from '../ActionBox';

const AvatarMenu = () => {
  const [feedbackLogout, setFeedbackLogout] = useState({
    type: '',
    title: '',
    message: '',
    closeButton: '',
    actionButton: '',
    open: false,
  });
  const { user } = useSelector((state) => state.Authentication.AuthReducer);
  const { isAdmin, personInCharge, isRTV } = user.userData;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRedirect = (to) => {
    if (to !== location.pathname) {
      history.push(to);
    }
  };

  const handleLogout = () => {
    setFeedbackLogout({
      type: 'LOGOUT',
      title: 'Você deseja sair?',
      message: 'Tem certeza que gostaria de sair?',
      closeButton: 'Sair',
      actionButton: 'Não Sair',
      open: true,
    });
  };

  const handleClose = () => {
    dispatch(actions.signOutAction());
  };

  return (
    <AvatarContainer isAdmin={isAdmin}>
      <NameTitle>
        <span>{personInCharge}</span>
        <span id="subtitle">
          {isAdmin ? 'Administrador' : isRTV ? 'Consultor' : 'Franqueado'}
        </span>
      </NameTitle>
      <ActionBox
        $header
        IconOpen={() => <ExpandLessIcon $isAdmin={isAdmin} />}
        IconClose={() => <ExpandMoreIcon $isAdmin={isAdmin} />}
        items={[
          {
            onClick: () => handleRedirect('/myprofile'),
            label: 'Perfil',
            icon: () => (
              <AccountCircleOutlinedIcon color="secondary" fontSize="small" />
            ),
          },
          {
            onClick: () => handleLogout(),
            label: 'Sair',
            icon: () => (
              <ExitToAppOutlinedIcon color="secondary" fontSize="small" />
            ),
          },
        ]}
      />
      <TwoActionsDialog
        open={feedbackLogout.open}
        type={feedbackLogout.type}
        title={feedbackLogout.title}
        message={feedbackLogout.message}
        closeButton={feedbackLogout.closeButton}
        actionButton={feedbackLogout.actionButton}
        handleAction={() =>
          setFeedbackLogout({ ...feedbackLogout, open: false })
        }
        handleClose={handleClose}
        handleCloseModal={() =>
          setFeedbackLogout({ ...feedbackLogout, open: false })
        }
      />
    </AvatarContainer>
  );
};

export default AvatarMenu;
