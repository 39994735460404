import * as actionTypes from '../Types/VisitsTable';

export const initState = {
  pages: [],
  page: 0,
  nextPageQuery: undefined,
  finishedReading: false,
  loading: true,
};

const VisitsTable = (state, action) => {
  if (typeof state === 'undefined') return initState;
  switch (action.type) {
    case actionTypes.VISITS_TABLE_SET_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case actionTypes.VISITS_TABLE_SET_FINISHED_READING:
      return {
        ...state,
        finishedReading: action.data,
      };
    case actionTypes.VISITS_TABLE_SET_NEXT_PAGE_QUERY:
      return {
        ...state,
        nextPageQuery: action.data,
      };
    case actionTypes.VISITS_TABLE_SET_PAGE:
      return {
        ...state,
        page: action.data,
      };
    case actionTypes.VISITS_TABLE_SET_PAGES:
      return {
        ...state,
        pages: action.data,
      };
    case actionTypes.VISITS_TABLE_SET_INITIAL_STATE:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default VisitsTable;
