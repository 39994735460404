import {
  doc,
  setDoc,
  getFirestore,
  collection,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  limit,
  orderBy,
} from 'firebase/firestore';
import { RTVUser } from '@/DTOs';
import firebaseApp from '../../firebase';
import { deleteUser } from '../../cloudFunctions';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

// Create a new RTVUser in firestore
export const createRTVUser = async (rtvUserObject) => {
  try {
    const newRTVUser = new RTVUser(rtvUserObject);
    return setDoc(
      doc(db, 'users', rtvUserObject.userId),
      newRTVUser.getObject
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

// update a RTVUser in firestore
export const updateRtvUser = (rtvId, rtvUserFields) =>
  updateDoc(doc(db, 'rtvUsers', rtvId), rtvUserFields);

// TODO: Test this function when integrate with front-end to make sure it works
// Delete a RTVUser in firestore and firebase authentication
export const deleteRtvUser = (rtvId) =>
  deleteDoc(doc(db, 'rtvUsers', rtvId))
    .then(() => deleteUser(rtvId))
    .catch((error) => error);

// Get specific RTVUser
export const getRtvUser = async (rtvId) => {
  try {
    const rtvUserRef = await doc(collection(db, 'rtvUsers'), rtvId);
    const rtvuserDoc = await getDoc(rtvUserRef);
    return rtvuserDoc.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Get all RTVUsers by userId
export const getAllRtvUsers = async (userId) => {
  try {
    // Get user doc to kwnow the is admin
    const userRef = await getDoc(doc(collection(db, 'users'), userId));
    const rtvUsersList = [];

    // if is adm user return all rtv users
    if (userRef.data().isAdmin) {
      await getDocs(collection(db, 'rtvUsers'))
        .then((querySnapshot) => {
          querySnapshot.docs.map((docSnap) =>
            rtvUsersList.push(docSnap.data())
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // if is not adm user return only rtv users from the same franchise
      const rtvDocsRef = await query(collection(db, 'rtvUsers'));
      const a = await query(
        rtvDocsRef,
        where('franchises', 'array-contains', userId)
      );
      const querySnapshot = await getDocs(a);
      querySnapshot.docs.map((element) => rtvUsersList.push(element.data()));
    }
    return rtvUsersList;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// Get all RTVUsers paginated
export const getPaginatedRtvUsers = (
  orderByToMake,
  queryToMake = query(
    collection(db, 'rtvUsers'),
    orderBy(orderByToMake),
    limit(10)
  )
) => makeQuery(queryToMake, 'rtvId').then((result) => result);
