import styled from 'styled-components';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { GlobalFontStyle, GlobalColors } from '@/Global/GlobalStyles';

export const AvatarContainer = styled.div`
  position: relative;
  color: ${({ isAdmin }) =>
    isAdmin
      ? GlobalColors.SupportColors.pureWhite
      : GlobalColors.SupportColors.blackLight};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const NameTitle = styled.div`
  font: ${GlobalFontStyle.H6.font};
  display: flex;
  flex-direction: column;
  margin-right: 28px;

  #subtitle {
    font-size: 10px !important;
  }
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  ${({ $isAdmin }) =>
    $isAdmin && `color: ${GlobalColors.SupportColors.pureWhite}`}
`;

export const ExpandLessIcon = styled(ExpandLess)`
  ${({ $isAdmin }) =>
    $isAdmin && `color: ${GlobalColors.SupportColors.pureWhite}`}
`;
