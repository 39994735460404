import {
  collection,
  query,
  getFirestore,
  addDoc,
  orderBy,
  deleteDoc,
  limit,
  getDoc,
  doc,
  collectionGroup,
  where,
  getDocs,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { deleteImage } from '@/services/storage';
import firebaseApp from '@/services/firebase';
import { Tip } from '@/DTOs';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/**
 * Get the number of optOuts of a tip, after it was sent.
 * @param {Array<string>} commodities - An array with the tips commodities categories.
 * @param {Array<string>} states - An array with the tips commodities states.
 * @param {Date} sendDate - The tip submission date
 * @param {string} userId - The userId of the franchisee logged in.
 * @returns - the number of optOuts
 */
export const getTipOptOuts = async (commodities, states, sendDate, userId) => {
  // Array that will store all the optOuts from the tip
  const optOutsIds = [];

  // Gets the number of optOuts with the same commodities
  const commoditiesQuery = query(
    collectionGroup(db, 'optOuts'),
    where('userId', '==', userId),
    where('commodityCategories.commodityType', 'in', [...commodities, 'TODAS']),
    where('date', '>=', sendDate)
  );
  const commoditiesSnapshot = await getDocs(commoditiesQuery);

  // Gets the number of optOuts with the same states
  const statesQuery = query(
    collectionGroup(db, 'optOuts'),
    where('userId', '==', userId),
    where('commodityCategories.state', 'in', [...states, 'TODOS']),
    where('date', '>=', sendDate)
  );
  const statesSnapshot = await getDocs(statesQuery);

  // Push the query results to the optOutsIds array
  commoditiesSnapshot.forEach((optOutDoc) => optOutsIds.push(optOutDoc.id));
  statesSnapshot.forEach((optOutDoc) => optOutsIds.push(optOutDoc.id));

  // Remove the duplicates
  const uniqOptOuts = [...new Set(optOutsIds)];

  // Returns the total number of optOuts
  return uniqOptOuts.length;
};

/**
 * Gets the number of sent tips.
 * @param {*} userId
 * @param {*} tipId
 * @returns - the number of sent tips
 */
export const getSentTipsMetric = async (userId, tipId) => {
  const path = `/usersTips/${userId}/tipsMetrics/${tipId}`;
  const docRef = doc(db, path);
  const docSnap = await getDoc(docRef);
  return docSnap.data().phoneNumbersSent;
};

/**
 * Function to create the view counter of a tip
 * @param {string} tipId
 * @param {string} userId
 * @returns a Promise that when solved, creates the tip counter
 */
const createTipCounter = (tipId, userId) => {
  // This number, allows for 100 counts a second, read more on: https://firebase.google.com/docs/firestore/solutions/counters
  const numShards = 100;

  const batch = writeBatch(db);

  const basePath = `usersTips/${userId}/tipsMetrics/${tipId}/counters/tipView`;

  // Initialize the counter document
  batch.set(doc(db, basePath), { numShards }, { merge: true });

  // Initialize each shard with count=0
  for (let i = 0; i < numShards; i++) {
    const shardRef = doc(db, `${basePath}/shards`, i.toString());
    batch.set(shardRef, { count: 0 });
  }

  // Commit the write batch
  return batch.commit();
};

/**
 * Function to create a counter of the tips section view
 * @param {string} tipId
 * @param {string} userId
 * @returns a Promise that when solved, creates the tips section view counter
 */
const createTipSectionCounter = (tipId, userId) => {
  // This number, allows for 100 counts a second, read more on: https://firebase.google.com/docs/firestore/solutions/counters
  const numShards = 100;

  const batch = writeBatch(db);

  const basePath = `usersTips/${userId}/tipsMetrics/${tipId}/counters/tipSectionView`;

  // Initialize the counter document
  batch.set(doc(db, basePath), { numShards }, { merge: true });

  // Initialize each shard with count=0
  for (let i = 0; i < numShards; i++) {
    const shardRef = doc(db, `${basePath}/shards`, i.toString());
    batch.set(shardRef, { count: 0 });
  }

  // Commit the write batch
  return batch.commit();
};

/**
 * Gets the view count from the tip counter
 * @param {string} tipId
 * @param {string} userId
 * @returns the count number
 */
export const getTipViewCount = async (userId, tipId) => {
  const basePath = `usersTips/${userId}/tipsMetrics/${tipId}/counters/tipView`;

  const snapshot = await getDocs(collection(db, `${basePath}/shards`));

  // Sum the count of each shard in the subcollection
  let totalCount = 0;
  snapshot.forEach((shardDoc) => {
    totalCount += shardDoc.data().count;
  });

  return totalCount;
};

/**
 * Gets the tips section view count, for a specific tip
 * @param {string} tipId
 * @param {string} userId
 * @returns the count number
 */
export const getTipsSectionViewCount = async (userId, tipId) => {
  const basePath = `usersTips/${userId}/tipsMetrics/${tipId}/counters/tipSectionView`;

  const snapshot = await getDocs(collection(db, `${basePath}/shards`));

  // Sum the count of each shard in the subcollection
  let totalCount = 0;
  snapshot.forEach((shardDoc) => {
    totalCount += shardDoc.data().count;
  });

  return totalCount;
};

// Function to create and save a new tip in firestore
export const createTip = async (newTip) => {
  const tipObj = new Tip(newTip);
  const path = `usersTips/${newTip.franchiseId}/tips`;
  const snapshot = await addDoc(collection(db, path), tipObj.getObject);
  await updateDoc(doc(db, path, snapshot.id), { tipId: snapshot.id });
  createTipCounter(snapshot.id, newTip.franchiseId);
  createTipSectionCounter(snapshot.id, newTip.franchiseId);
  return snapshot.id;
};

/** Get paginated user's tips.
 * @param {string} franchiseId
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page.
 */
export const getFranchiseTips = (
  franchiseId,
  orderByToMake,
  queryToMake = query(
    collection(db, `usersTips/${franchiseId}/tips`),
    orderBy(orderByToMake, 'desc'),
    limit(10)
  )
) => makeQuery(queryToMake, 'tipId').then((resul) => resul);

/** Get tip data
 * @param {string} tipId
 */

export const getTipData = (franchiseId, tipId) =>
  getDoc(doc(db, `usersTips/${franchiseId}/tips`, tipId)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), franchiseId: docSnap.id };
    }
    return null;
  });

// Get all customer categories by franchiseId
export const getTipsCategory = async (franchiseId) => {
  const categories = {
    states: ['TODOS'],
    commodites: ['TODAS'],
  };
  // Get all customers from a franchise
  const queryToMake = getDocs(
    query(
      collectionGroup(db, `phoneNumbers`),
      where('userId', '==', franchiseId)
    )
  );
  const customerList = (await queryToMake).docs.map((docSnap) =>
    docSnap.data()
  );

  // Get categories from customers
  const usersCategories = customerList.map(
    (customer) => customer.commodityFavoriteList
  );

  // Concatenate all categories subarrays in one array
  const allusersCategories = usersCategories.flat();

  // Populate categories array with states and commodites
  allusersCategories.forEach((category) => {
    categories.states.push(category.state);
    categories.commodites.push(category.commodityType);
  });

  // Remove duplicates
  categories.states = [...new Set(categories.states)];
  categories.commodites = [...new Set(categories.commodites)];
  return categories;
};

// Update all fields passed in the object "updateField"
// OBS: to work all fields must be have the same name that names in  DB
export const updateTip = async (franchiseId, tipId, updateField) => {
  // Get tip reference in firestore
  const tipToUpdate = doc(db, `usersTips/${franchiseId}/tips/${tipId}`);
  // Update tip
  await updateDoc(tipToUpdate, updateField);
  // If want a return in this function, uncomment this lines
  // .then(() => true)
  // .catch((err) => {
  //   console.log(err);
  //   return false;
  // })
};

// Delete specific franchise tip
export const deleteTip = (userId, tipToDelete) => {
  // Try delete image until delete tip data
  deleteImage(userId, tipToDelete.imageRef, tipToDelete.tipId)
    .then(() => {
      deleteDoc(doc(db, `usersTips/${userId}/tips`, tipToDelete.tipId));
    })
    .catch(() => {
      // If Tip don't have image, just delete tip date
      deleteDoc(doc(db, `usersTips/${userId}/tips`, tipToDelete.tipId));
    });
};
