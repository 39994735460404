import * as actionTypes from '../Types/AuthTypes';

export const initState = {
  user: null,
  signInError: null,
  signUpError: null,
  loadingAuth: true,
};

const AuthReducer = (state, action) => {
  if (typeof state === 'undefined') return initState;
  switch (action.type) {
    case actionTypes.AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        signInError: null,
        loadingAuth: false,
      };

    case actionTypes.AUTH_SIGNIN_ERROR:
      return {
        ...state,
        signInError: action.message,
        user: null,
        loadingAuth: false,
      };

    case actionTypes.AUTH_SIGNOUT_SUCCESS:
      return {
        ...state,
        signInError: null,
        user: null,
        loadingAuth: false,
      };

    case actionTypes.AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        signUpError: null,
        loadingAuth: false,
      };

    case actionTypes.AUTH_SIGNUP_ERROR:
      return {
        ...state,
        signUpError: action.message,
        loadingAuth: false,
      };

    case actionTypes.AUTH_SET_USER_STATE:
      return {
        ...state,
        signInError: null,
        user: action.user,
        loadingAuth: action.loadingState,
      };

    case actionTypes.AUTH_SET_LOADING:
      return {
        ...state,
        loadingAuth: action.value,
      };

    case actionTypes.AUTH_CLEAN_UP_ERRORS:
      return {
        ...state,
        signInError: null,
        signUpError: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;
