class Visit {
  constructor(visitObject) {
    const {
      addressToVisit,
      city,
      commodity,
      franchiseId,
      franchiseName,
      lastUpdate,
      phoneNumber,
      solicitationDate,
      confirmationDate,
      dateVisitRtvInfo,
      visitingDate,
      subject,
      textContent,
      visitStatus,
      email,
      customerName,
      phenologicalStage,
      responsableForTheVisit,
      rtvId,
      customerId,
      visitId,
      recommendedProducts,
      title,
      subjectRtvInfo,
      descriptionRtvInfo,
    } = visitObject;

    if (
      !addressToVisit ||
      !city ||
      !commodity ||
      !franchiseId ||
      !franchiseName ||
      !lastUpdate ||
      !solicitationDate ||
      !visitStatus ||
      !customerName ||
      !responsableForTheVisit ||
      !rtvId ||
      !customerId ||
      !visitId
    )
      throw new Error('One or more parameters undefined');

    this.addressToVisit = addressToVisit;
    this.city = city;
    this.commodity = commodity;
    this.franchiseId = franchiseId;
    this.franchiseName = franchiseName;
    this.lastUpdate = lastUpdate;
    this.solicitationDate = solicitationDate;
    this.visitStatus = visitStatus;
    this.email = email;
    this.customerName = customerName;
    this.phenologicalStage = phenologicalStage;
    this.responsableForTheVisit = responsableForTheVisit;
    this.rtvId = rtvId;
    this.customerId = customerId;
    this.visitId = visitId;
    this.textContent = textContent || '';
    this.subject = subject || '';
    this.email = email || '';
    this.phenologicalStage = phenologicalStage || '';
    this.phoneNumber = phoneNumber || '';
    this.recommendedProducts = recommendedProducts || [];
    this.confirmationDate = confirmationDate || '';
    this.dateVisitRtvInfo = dateVisitRtvInfo || '';
    this.visitingDate = visitingDate || '';
    this.title = title || '';
    this.subjectRtvInfo = subjectRtvInfo || '';
    this.descriptionRtvInfo = descriptionRtvInfo || '';
  }

  get getObject() {
    return {
      addressToVisit: this.addressToVisit,
      city: this.city,
      commodity: this.commodity,
      franchiseId: this.franchiseId,
      franchiseName: this.franchiseName,
      lastUpdate: this.lastUpdate,
      phoneNumber: this.phoneNumber,
      solicitationDate: this.solicitationDate,
      subject: this.subject,
      textContent: this.textContent,
      visitStatus: this.visitStatus,
      email: this.email,
      customerName: this.customerName,
      phenologicalStage: this.phenologicalStage,
      responsableForTheVisit: this.responsableForTheVisit,
      rtvId: this.rtvId,
      customerId: this.customerId,
      visitId: this.visitId,
      recommendedProducts: this.recommendedProducts,
      confirmationDate: this.confirmationDate,
      dateVisitRtvInfo: this.dateVisitRtvInfo,
      visitingDate: this.visitingDate,
      title: this.title,
      subjectRtvInfo: this.subjectRtvInfo,
      descriptionRtvInfo: this.descriptionRtvInfo,
    };
  }
}

export default Visit;
