import styled from 'styled-components';
import {
  GlobalColors,
  GlobalFontStyle,
  BorderStyle,
} from '@/Global/GlobalStyles';

export const Header = styled.div`
  background-color: ${GlobalColors.SupportColors.pureWhite};
  height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
`;

export const Menu = styled.div`
  display: flex;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 51px;
  padding: 24px 8px;
  ${({ active }) =>
    active &&
    `border-bottom: ${BorderStyle.Density.standard} solid ${GlobalColors.Primary.verdePrimario};`}
  color: ${({ active }) =>
    active
      ? GlobalColors.Primary.azulPrimario
      : GlobalColors.SupportColors.grayMedium};
  font: ${({ active }) =>
    active ? GlobalFontStyle.H3.font : GlobalFontStyle.H4.font};
`;


export const DivLogo = styled.div`
  @media (max-width: 1024px) {
    margin-left: 30%;
  }
  @media (max-width: 480px) {
    margin-left: 80px;
  }
  @media (max-width: 410px) {
    margin-left: 30px;
  }
  @media (max-width: 393px) {
    margin-left: 45px;
  }
`;
