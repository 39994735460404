class RTVUser {
  constructor(rtvUserObject) {
    const {
      name,
      location,
      email,
      franchises,
      userId,
      phoneNumber,
      lastUpdate,
      isRTV,
    } = rtvUserObject;

    if (
      !email &&
      !name &&
      !location &&
      !telephone &&
      !franchises &&
      !userId &&
      !lastUpdate
    )
      throw new Error(
        'email, name, location, telephone, lastUpdate, rtvId or franchises undefined'
      );

    this.email = email;
    this.name = name;
    this.location = location;
    this.phoneNumber = phoneNumber;
    this.franchises = franchises;
    this.userId = userId;
    this.lastUpdate = lastUpdate;
    this.isRTV = isRTV;
  }

  get getObject() {
    return {
      email: this.email,
      name: this.name,
      location: this.location,
      phoneNumber: this.phoneNumber,
      franchises: this.franchises,
      userId: this.userId,
      lastUpdate: this.lastUpdate,
      isRTV: this.isRTV,
    };
  }
}

export default RTVUser;
