import { Dialog } from '@material-ui/core';
import ErrorImage from '@/Assets/DialogFeedback/error-sending.svg';
import FileSentImage from '@/Assets/DialogFeedback/file-sent.svg';
import FranchiseeRegistrationSuccessImage from '@/Assets/DialogFeedback/registration-add.svg';
import KnowMoreImage from '@/Assets/DialogFeedback/know-more.svg';
import {
  DialogContent,
  StyledButton,
  DialogContentTitle,
  DialogContentText,
} from './DialogFeedback_style';

const DialogFeedback = ({
  open,
  handleClose,
  type,
  title,
  message,
  success = true,
  sugestionLink = false
}) => {
  const switchImage = () => {
    switch (type) {
      case 'KnowMore':
        return <img src={KnowMoreImage} alt="KnowMore" />;
      case 'ErrorSending':
        return <img src={ErrorImage} alt="ErrorSending" />;
      case 'FileSent':
        return <img src={FileSentImage} alt="FileSent" />;
      case 'PasswordChangeError':
        return <img src={ErrorImage} alt="FranchiseeRegistrationError" />;
      case 'PasswordChangeSuccess':
        return <img src={FranchiseeRegistrationSuccessImage} alt="FranchiseeRegistrationSuccess" />;
      case 'FranchiseeRegistrationError':
        return <img src={ErrorImage} alt="FranchiseeRegistrationError" />;
      case 'FranchiseeRegistrationSuccess':
      default:
        return (
          <img
            src={FranchiseeRegistrationSuccessImage}
            alt="FranchiseeRegistrationSuccess"
          />
        );
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(success)}>
      <DialogContent>
        {switchImage()}
        <DialogContentTitle>
          <b>{title}</b>
        </DialogContentTitle>
        <DialogContentText>
          <span>{message}</span>
          {sugestionLink && (
            <div>
              <span>{'Clique '}</span>
              <a href={sugestionLink}>aqui</a>
              <span>{' para baixar o modelo.'}</span>
            </div>
          )}
        </DialogContentText>
        <StyledButton
          id="dialog-button"
          variant="contained"
          color="primary"
          onClick={() => handleClose(success)}
        >
          Fechar
        </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default DialogFeedback;
