import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

export const deleteUser = (uid) =>
  httpsCallable(functions, 'deleteUser')({ uid });

export const isUserActive = async (email) => {
  const { data } = await httpsCallable(functions, 'isUserActive')({ email });
  return data;
};

// export const updateRTVPassword = (uid, pass) =>
//   httpsCallable(functions, 'updateRTVPassword')({ uid, pass });

/** **deleteUser**: https://us-central1-goplan-dev.cloudfunctions.net/deleteUser */
// export const deleteUser = (uid) => axios.get(`https://us-central1-goplan-dev.cloudfunctions.net/deleteUser?uid=${uid}`).then((res) => res.data).catch((error) => error);
