export const round = (num, places) => {
  if (!` ${num}`.includes('e')) {
    const mathRoundWithE = Math.round(Number(`${num}e+${places}`));
    return +`${mathRoundWithE}e-${places}`;
  }
  const arr = ` ${num}`.split('e');
  let sig = '';
  if (+arr[1] + places > 0) {
    sig = '+';
  }

  const mathRound = Math.round(Number(`${+arr[0]}e${sig}${+arr[1] + places}`));
  return +`${mathRound}e-${places}`;
};
