class User {
  constructor(userObject) {
    const {
      name,
      location,
      personInCharge,
      phoneNumber,
      email,
      system,
      isAdmin,
      isRTV,
      franchisees,
      lastUpdate,
      userId,
      createdByAdmin,
      isActive
    } = userObject;

    if (!email || !system || !userId) {
      throw new Error(' Undefined email, system or userId');
    }

    this.email = email;
    this.system = system;
    this.userId = userId;

    this.name = name || '';
    this.location = location || null;
    this.personInCharge = personInCharge || null;
    this.phoneNumber = phoneNumber || null;
    this.isAdmin = isAdmin || false;
    this.lastUpdate = lastUpdate || new Date(0);
    this.isRTV = isRTV || false;
    this.franchisees = franchisees || [];
    this.createdByAdmin = createdByAdmin || false;
    this.isActive = isActive === undefined ? true : isActive;
  }

  get getObject() {
    return {
      email: this.email,
      system: this.system,
      userId: this.userId,
      name: this.name,
      location: this.location,
      personInCharge: this.personInCharge,
      phoneNumber: this.phoneNumber,
      isAdmin: this.isAdmin,
      lastUpdate: this.lastUpdate,
      isRTV: this.isRTV,
      franchisees: this.franchisees,
      createdByAdmin: this.createdByAdmin,
      isActive: this.isActive
    };
  }
}

export default User;
