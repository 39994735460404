class PhoneNumber {
  constructor(phoneNumberObject) {
    const {
      phoneNumber,
      birthDate,
      city,
      state,
      customerId,
      system,
      userId,
      franchiseeName,
      franchiseePhoneNumber,
      customerName,
      nickname,
      commodityFavoriteList,
    } = phoneNumberObject;

    if (
      !phoneNumber ||
      !customerId ||
      !customerName ||
      !userId ||
      !system ||
      !franchiseeName ||
      !franchiseePhoneNumber
    )
      throw new Error(
        'Undefined phoneNumber, customerId, customerName, userId, system, franchiseeName or franchiseePhoneNumber'
      );

    this.phoneNumber = phoneNumber;
    this.customerId = customerId;
    this.customerName = customerName;
    this.userId = userId;
    this.system = system;
    this.franchiseeName = franchiseeName;
    this.franchiseePhoneNumber = franchiseePhoneNumber;

    this.dollarNotification = true;
    this.weatherNotification = true;
    this.ordersNotification = true;
    this.tipsNotification = true;
    this.visitsNotification = true;
    this.tipsSectionView = false;

    this.birthDate = birthDate || null;
    this.city = city || null;
    this.state = state || null;
    this.nickname = nickname || customerName;
    this.commodityFavoriteList = commodityFavoriteList || [];
  }

  get getObject() {
    return {
      phoneNumber: this.phoneNumber,
      customerId: this.customerId,
      customerName: this.customerName,
      userId: this.userId,
      system: this.system,
      franchiseeName: this.franchiseeName,
      franchiseePhoneNumber: this.franchiseePhoneNumber,
      birthDate: this.birthDate,
      city: this.city,
      state: this.state,
      nickname: this.nickname,
      commodityFavoriteList: this.commodityFavoriteList,
      dollarNotification: this.dollarNotification,
      weatherNotification: this.weatherNotification,
      ordersNotification: this.ordersNotification,
      tipsNotification: this.tipsNotification,
      visitsNotification: this.visitsNotification,
      tipsSectionView: this.tipsSectionView,
    };
  }
}

export default PhoneNumber;
