import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  runTransaction,
  collectionGroup,
  orderBy,
  query,
  startAfter,
  where,
  updateDoc,
  getFirestore,
} from 'firebase/firestore';
import firebaseApp from '@/services/firebase';
import { spliceIntoChunks } from '@/Global/Utils';
import { appendError } from '@/services/storage';
import { Customer, PhoneNumber } from '@/DTOs';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/** Aux Function to be called whenever an error occurs */
const errorFunction = (err) => {
  appendError({
    path: 'src/services/dbFunctions/Customers/index.js',
    message: err.message,
  });
  throw new Error(err);
};

/** Custom function that makes a paginated query to get customers and their phone numbers
 * @param {Object} queryToMake - Query to be made by this function
 * @param {Object} userId - userId where the customer is located
 */
const customMakeQuery = async (queryToMake, userId) =>
  getDocs(queryToMake).then(async (documentSnapshots) => {
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    let nextQuery;
    if (lastVisible) {
      nextQuery = query(queryToMake, startAfter(lastVisible));
    } else {
      nextQuery = null;
    }

    const docs = documentSnapshots.docs.map(async (docSnap) => {
      const phoneNumbers = await getDocs(
        collection(db, `users/${userId}/customers/${docSnap.id}/phoneNumbers`)
      ).then((res) => res.docs.map((e) => e.data()));
      return {
        ...docSnap.data(),
        phoneNumbers,
        customerId: docSnap.id,
      };
    });

    const a = await Promise.all(docs);
    return { docs: a, nextQuery };
  });

/** Adds a customer of an user
 * @param {Object} customerData
 */
export const addCustomersData = (customersData, t) => {
  try {
    customersData.forEach((customerData) => {
      const {
        customerId,
        create,
        update,
        userId,
        customerWithoutPhoneNumber,
      } = customerData;
      const path = `users/${userId}/customers`;
      const customerRef = doc(db, path, customerId);

      const customerObj = new Customer(customerWithoutPhoneNumber);

      // If the customer exists update  the customer itself
      if (update) {
        t.update(customerRef, customerObj.getObject);
      } else if (create) {
        t.set(customerRef, customerObj.getObject);
      }
    });
  } catch (err) {
    errorFunction(err);
  }
};

/** Adds a customer of an user
 * @param {Object} customerData
 */
export const addCustomersPhoneNumber = (customerPhoneNumbersData, t) => {
  try {
    customerPhoneNumbersData.forEach((customerPhoneNumberData) => {
      const {
        userId,
        customerId,
        phoneNumbersToAdd,
        phoneNumbersToDelete,
      } = customerPhoneNumberData;

      // Deletes the phoneNumbers that are not in the passed phoneNumbers Array
      phoneNumbersToDelete.forEach((phoneNumber) => {
        const ref = doc(
          db,
          `users/${userId}/customers/${customerId}/phoneNumbers`,
          phoneNumber.phoneNumber
        );
        t.delete(ref);
      });

      // Creates the phoneNumbers that are not in the phoneNumbers collection but are in the uploaded file
      phoneNumbersToAdd.forEach((phoneNumber) => {
        const phoneNumberObject = new PhoneNumber(phoneNumber);
        const ref = doc(
          db,
          `users/${userId}/customers/${customerId}/phoneNumbers`,
          phoneNumber.phoneNumber
        );
        t.set(ref, phoneNumberObject.getObject);
      });
    });
  } catch (err) {
    errorFunction(err);
  }
};

/** Gets all phone numbers related to the given customers and userId
 * @param {Object[]} customers - Must be an array of 'customer'
 * @param {string} userId - Must be a string
 */
const getListOfCustomersPhoneNumbers = async (customers, userId) => {
  const phoneNumbersSnap = await getDocs(
    query(collectionGroup(db, 'phoneNumbers'), where('userId', '==', userId))
  );
  const customerOfCurrentFranchisePhones = phoneNumbersSnap.docs.map((docs) =>
    docs.data()
  );
  return customers
    .map((customer) => {
      const { customerId, phoneNumbers } = customer;
      const phoneNumbersToDelete = customerOfCurrentFranchisePhones
        .filter((snap) => snap.customerId === customerId)
        .filter((snap) =>
          phoneNumbers.every((item) => item.phoneNumber !== snap.phoneNumber)
        );
      const phoneNumbersToAdd = phoneNumbers.filter((item) => {
        if (customerOfCurrentFranchisePhones.length === 0) return true;
        return customerOfCurrentFranchisePhones
          .filter((snap) => snap.customerId === customerId)
          .every((docS) => item.phoneNumber !== docS.phoneNumber);
      });

      return {
        userId,
        customerId,
        phoneNumbersToDelete,
        phoneNumbersToAdd,
      };
    })
    .filter(
      (phone) =>
        phone.phoneNumbersToDelete.length > 0 ||
        phone.phoneNumbersToAdd.length > 0
    );
};

/** Gets all information stored in firestore related to the given customers and userId
 * @param {Object[]} customers - Must be an array of 'customer'
 * @param {string} userId - Must be a string
 */
const getListOfCustomers = async (customers, userId) => {
  const customersSnap = await getDocs(
    query(collectionGroup(db, 'customers'), where('userId', '==', userId))
  );
  const promises = customers.map((customer) => {
    const { customerId } = customer;
    const { phoneNumbers, ...customerWithoutPhoneNumber } = customer;
    const customerAlreadyExists =
      customersSnap.docs
        .map((docs) => docs.data())
        .findIndex((snap) => snap.customerId === customerId) >= 0;

    // If the customer exists update the phone numbers and the customer itself
    const customerObject = {
      update: false,
      create: true,
      userId,
      customerId,
      customerWithoutPhoneNumber,
    };
    if (customerAlreadyExists) {
      customerObject.update = true;
      customerObject.create = false;
    }
    return customerObject;
  });

  return Promise.all(promises)
    .then((res) => res)
    .catch(errorFunction);
};

/** Adds multiple customers.
 * @param {string} userId
 * @param {Object[]} customersSingleArray - Must be an array of 'customer'
 * objects.
 */
export const addCustomers = async (userId, customersSingleArray) => {
  try {
    // Split the customersSingleArray into an array of arrays, with max length of 200 elements
    const customersMultipleArray = spliceIntoChunks(customersSingleArray, 200);

    // Is created an array of promises because a single transaction run only 500 operations
    const promises = customersMultipleArray.map(async (customers) => {
      const listOfCustomersPhoneNumbers = await getListOfCustomersPhoneNumbers(
        customers,
        userId
      );
      const listOfCustomers = await getListOfCustomers(customers, userId);
      return runTransaction(db, async (t) => {
        addCustomersData(listOfCustomers, t);
        addCustomersPhoneNumber(listOfCustomersPhoneNumbers, t);
      })
        .then(() => true)
        .catch(errorFunction);
    });
    await Promise.all(promises)
      .then((res) => [...res].every((value) => value))
      .catch(errorFunction);
  } catch (err) {
    errorFunction(err);
  }
};

/** Gets a specific customer from a user
 * @param {string} userId
 * @param {string} customerId
 */
export const getCustomerData = (userId, customerId) =>
  getDoc(doc(db, `users/${userId}/customers`, customerId)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), customerId: docSnap.id };
    }
    return null;
  });

/** Gets all customers from a user
 * @param {string} userId
 */
export const getAllUserCustomers = (userId) =>
  getDocs(collection(db, `users/${userId}/customers`)).then((querySnapshot) =>
    querySnapshot.docs.map((docSnap) => ({
      ...docSnap.data(),
      customerId: docSnap.id,
    }))
  );

/** Get paginated user's customers Returns an object with an array of the
 * customers data and an parameter 'nextQuery', which can be passed as a
 * parameter to the next call of this function, to give the next page.
 * @param {string} userId
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedUserCustomers = (
  userId,
  orderByToMake,
  queryToMake = query(
    collection(db, `users/${userId}/customers`),
    orderBy(orderByToMake, 'desc'),
    orderBy('customerName'),
    limit(10)
  )
) => customMakeQuery(queryToMake, userId).then((result) => result);

/** Update a customer's data
 * @param {string} userId
 * @param {string} customerId
 * @param {Object} customerData
 */
export const updateCustomerData = (userId, customerId, customerData) =>
  updateDoc(doc(db, `users/${userId}/customers`, customerId), customerData);

/** Deletes a customer
 * @param {string} userId
 * @param {string} customerId
 */
export const deleteCustomer = (userId, customerId) =>
  deleteDoc(doc(db, `users/${userId}/customers`, customerId));
