import styled from 'styled-components';
import { GlobalColors } from '@/Global/GlobalStyles';

export const Header = styled.div`
  background-color: ${GlobalColors.Primary.azulPrimario};
  height: 62px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 28px;
`;
