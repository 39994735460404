import {
  collection,
  limit,
  orderBy,
  query,
  getFirestore,
  updateDoc,
  doc,
  getDoc,
  collectionGroup,
  where,
  setDoc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import firebaseApp from '@/services/firebase';
import Visit from '@/DTOs/Visit';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);

/**
 * Creates a visit
 * @param {string} franchiseId
 * @param {object} visitData
 */
export const createVisit = async (franchiseId, visitData) => {
  const visitRef = doc(collection(db, `scheduledVisit/${franchiseId}/visits`));
  const visitObj = new Visit({ ...visitData, visitId: visitRef.id });
  await setDoc(visitRef, visitObj.getObject);
  return visitRef.id;
};

// Get the visit data
export const getVisitData = async (franchiseId, visitId) =>
  getDoc(doc(db, `scheduledVisit/${franchiseId}/visits`, visitId)).then(
    (resul) => {
      if (resul.exists()) return { ...resul.data() };
      return null;
    }
  );

// Get all visits
export const getAllVisits = (
  franchiseId,
  orderByToMake,
  queryToMake = query(
    collection(db, `scheduledVisit/${franchiseId}/visits`),
    orderBy(orderByToMake, 'desc'),
    limit(10)
  )
) => makeQuery(queryToMake, 'visitId').then((resul) => resul);

export const getAllVisitsToMetrics = async (franchiseId) => {
  try {
    const queryToMake = query(
      collection(db, `scheduledVisit/${franchiseId}/visits`)
    );
    const visitDocs = await getDocs(queryToMake);
    const visitsArray = [];
    visitDocs.docs.forEach((d) => {
      visitsArray.push(d.data());
    });
    return visitsArray;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Get all visits
export const getRTVVisits = (
  rtvId,
  orderByToMake,
  queryToMake = query(
    collectionGroup(db, 'visits'),
    where('rtvId', '==', rtvId),
    orderBy(orderByToMake, 'desc'),
    limit(10)
  )
) =>
  makeQuery(queryToMake, 'visitId')
    .then((resul) => resul)
    .catch((err) => console.log(err));

// Update the visit for change the status, just pass the fields that you want to update in object
export const updateVisit = async (franchiseId, visitId, updateField) => {
  // Get document to update
  try {
    const visitToUpdate = doc(
      db,
      `scheduledVisit/${franchiseId}/visits/${visitId}`
    );
    // Update document
    await updateDoc(visitToUpdate, updateField);
  } catch (err) {
    console.log(err);
  }
};

/**
 * Deletes a visit
 * @param {string} franchiseId
 * @param {string} visitId
 * @returns a `Promise` that when solved, deletes the visit document.
 */
export const deleteVisit = (franchiseId, visitId) =>
  deleteDoc(doc(db, `scheduledVisit/${franchiseId}/visits/${visitId}`));
