import React from 'react';
import AvatarMenu from '@/Global/Components/AvatarMenu';
import { Header } from './AdminHeader_style';

const AdminHeader = () => (
  <Header>
    <AvatarMenu />
  </Header>
);

export default AdminHeader;
