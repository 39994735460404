import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from '@/Redux';
import DialogFeedback from '@/Global/Components/DialogFeedback';
import GoplanLogo from '@/Assets/Goplan/goplan-logo.svg';
import ImgAddFile from '@/Assets/SideMenu/add-files.svg';
import {
  SideMenuContainer,
  Content,
  Logo,
  SideMenuTab,
  SideMenuLink,
  KnowMoreContainer,
  AddFiles,
  StyledButton,
  MuiAccountBox,
  MuiAssignmentInd,
  // MuiDescription,
  MuiPermContactCalendar,
  MuiDescription,
  MuiVisitsIcon,
} from './SideMenu_style';
import { version } from '../../../../../../package.json';

const SideMenu = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.Authentication.AuthReducer);
  const userSystem = user.userData.system;
  const [dialogFeedback, setDialogFeedback] = useState({
    type: '',
    title: '',
    message: '',
    open: false,
    success: true,
  });
  const [counter, setCounter] = useState(0);

  const handleClose = () => {
    setDialogFeedback({
      ...dialogFeedback,
      open: false,
    });
  };

  const handleOpen = () => {
    setDialogFeedback({
      type: 'KnowMore',
      title: `Padrão ideal para ${
        // TODO: Alterar o nome do novo ERP
        (userSystem === 'Siagri' && 'SIAGRI') ||
        (userSystem === 'SAP' && 'SAP') ||
        (userSystem === 'AGROTITAN' && 'AGROTITAN')
      }`,
      message: `Para realizar o envio do arquivo o formato deve ser ${
        (userSystem === 'Siagri' && '.xls') ||
        (userSystem === 'SAP' && '.xlsx') ||
        (userSystem === 'AGROTITAN' && '.xls')
      }.`,
      sugestionLink: (userSystem === 'Siagri' && process.env.REACT_APP_DOWNLOAD_CUSTOMERS_SIAGRI) ||
      (userSystem === 'SAP' && process.env.REACT_APP_DOWNLOAD_CUSTOMERS_SAP) ||
      (userSystem === 'AGROTITAN' && process.env.REACT_APP_DOWNLOAD_CUSTOMERS_AGROTITAN),
      open: true,
      success: true,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(0);
    }, 1000);

    if (counter === 10) {
      // VERSION
      // eslint-disable-next-line
      alert(`Versão: ${version}\nAmbiente: ${process.env.REACT_APP_ENV || 'local'}`);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  return (
    <SideMenuContainer>
      <Content>
        <Logo
          src={GoplanLogo}
          onClick={() => {
            setCounter((oldState) => oldState + 1);
          }}
        />
        <SideMenuTab>
          {/* Orders menu temporarily disabled because of LGPD issues. */}
          {/* <SideMenuLink $active={pathname === '/orders'} to="/orders">
            <MuiDescription $active={pathname === '/orders'} to="/orders" />
            Pedidos
          </SideMenuLink> */}          
          <SideMenuLink $active={pathname === '/customers'} to="/customers">
            <MuiAccountBox
              $active={pathname === '/customers'}
              to="/customers"
            />
            Clientes
          </SideMenuLink>
          <SideMenuLink
            $active={
              pathname === '/franchisee' ||
              pathname === '/userregister' ||
              pathname === '/edituser'
            }
            to="/franchisee"
          >
            <MuiPermContactCalendar
              $active={
                pathname === '/franchisee' ||
                pathname === '/userregister' ||
                pathname === '/edituser'
              }
            />
            Franqueados
          </SideMenuLink>
          <SideMenuLink
            $active={
              pathname === '/rtv-users' ||
              pathname === '/create-rtv' ||
              pathname.split('/')[1] === 'create-rtv'
              }
            to="/rtv-users">
            <MuiAssignmentInd
              $active={
                pathname === '/rtv-users' ||
                pathname === '/create-rtv' ||
                pathname.split('/')[1] === 'create-rtv'
                }
              to="/rtv-users"
            />
            RTV
          </SideMenuLink>
          <SideMenuLink $active={pathname === '/tips'} to="/tips">
          <MuiDescription $active={pathname === '/tips'} to="/tips" />
            Dicas
          </SideMenuLink>
          <SideMenuLink $active={pathname === '/visits'} to="/visits">
          <MuiVisitsIcon active={pathname === '/visits'} to="/visits" />
            Visitas
          </SideMenuLink>
        </SideMenuTab>
      </Content>
      <KnowMoreContainer>
        <AddFiles src={ImgAddFile} />
        <p id="content">
          Confira o <b>padrão</b> ideal <br />
          de envio do seu arquivo.
        </p>
        <StyledButton
          id="know-more"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleOpen}
        >
          Saiba mais
        </StyledButton>
      </KnowMoreContainer>
      <DialogFeedback
        open={dialogFeedback.open}
        type={dialogFeedback.type}
        title={dialogFeedback.title}
        message={dialogFeedback.message}
        success={dialogFeedback.success}
        sugestionLink={dialogFeedback.sugestionLink}
        handleClose={handleClose}
      />
    </SideMenuContainer>
  );
};
export default SideMenu;
