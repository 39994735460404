export const spliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  const arr2 = [...arr];
  while (arr2.length > 0) {
    const chunk = arr2.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
