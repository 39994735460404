export const AUTH_SIGNIN_ERROR = 'AUTH_SIGNIN_ERROR';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';
export const AUTH_SET_USER_STATE = 'AUTH_SET_USER_STATE';
export const AUTH_SET_LOADING = 'AUTH_SET_LOADING';
export const AUTH_CLEAN_UP_ERRORS = 'AUTH_CLEAN_UP_ERRORS';
export const AUTH_RECOVER_PASSWORD = 'AUTH_RECOVER_PASSWORD';
export const AUTH_LIST_USERS = 'AUTH_LIST_USERS';
