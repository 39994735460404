import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { GlobalColors, GlobalFontStyle } from '../../GlobalStyles';

export const IconButton = styled(MuiIconButton)`
  margin-right: 12px;
`;

export const Toolbar = styled.section`
  display: flex;
  align-items: center;
  background-color: ${GlobalColors.Primary.verdePrimario};
  height: 186px;
  img {
    padding-left: 24px;
  }
`;

export const UserName = styled.span`
  ${GlobalFontStyle.H3.medium}
  color: ${GlobalColors.SupportColors.pureWhite};
  padding-left: 18px;
  padding-right: 18px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
