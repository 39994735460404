import { Dialog } from '@material-ui/core';
import Logout from '@/Assets/DialogFeedback/logout.svg';
import Delete from '@/Assets/DeleteDialog/delete.svg';
import {
  DialogContent,
  StyledButton,
  StyledLastButton,
  DialogContentTitle,
  DialogContentText,
} from './TwoActionsDialog_style';

const TwoActionsDialog = ({
  open,
  type,
  title,
  message,
  closeButton,
  actionButton,
  handleClose,
  handleAction,
  handleCloseModal
}) => {
  const switchImage = () => {
    switch (type) {
      case 'Delete':
        return <img src={Delete} alt="Delete" />;
      case 'Logout':
      default:
        return <img src={Logout} alt="Logout" />;
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal || handleClose}>
      <DialogContent>
        {switchImage()}
        <DialogContentTitle>
          <b>{title}</b>
        </DialogContentTitle>
        <DialogContentText>
          <span>{message}</span>
        </DialogContentText>
        <StyledButton variant="contained" color="primary" onClick={handleClose} style={{ marginBottom: '10px' }}>
          {closeButton}
        </StyledButton>
        <StyledLastButton color="primary" onClick={handleAction} style={{ marginBottom: '5px' }}>
          {actionButton}
        </StyledLastButton>
      </DialogContent>
    </Dialog>
  );
};

export default TwoActionsDialog;
