import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { useSelector as useReduxSelector } from 'react-redux';
import thunk from 'redux-thunk';

// Persists the reducer and store, so that user information
// is not lost
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import Authentication from './Global/Modules/Login/Redux/Reducers';

import Customers from './Modules/Customers/Redux/Reducer';
import Orders from './Modules/Orders/Redux/Reducer';
import Franchisee from './Modules/Franchisee/Redux/Reducer';
import Tips from './Modules/Tips/Redux/Reducer';
import Visits from './Modules/Visits/Redux/Reducer';
import RTVs from './Modules/RtvUsers/Redux/Reducer';

const RootReducers = combineReducers({
  Authentication,
  Customers,
  Orders,
  Franchisee,
  Tips,
  Visits,
  RTVs,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage: localStorage,
  // Blacklists firebase and firestore reducers, because they have their
  // own way to persist information.
  // blacklist: ['firebase', 'firestore'],
  whitelist: ['Authentication'],
};
export const persistedReducer = persistReducer(persistConfig, RootReducers);

export const useSelector = useReduxSelector;

export const enhancers = composeEnhancers(applyMiddleware(thunk));

export default () => {
  /** The applyMiddleware applies a middleware (thunk) to enhance the
   * store functionality. One of this enhancements is the ability to
   * return functions inside the action creators. */
  const store = createStore(persistedReducer, enhancers);

  const persistor = persistStore(store);
  return { store, persistor };
};
