import styled from 'styled-components';
import { GlobalColors } from '@/Global/GlobalStyles';

export const ContainerFlex = styled('div')`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const ContainerMain = styled('div')`
  background-color: ${GlobalColors.SupportColors.whiteLight};
  height: 100%;
  overflow-y: auto;
`;

export const ContentColumn = styled('div')`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '84%' : '100%')};
`;

export const LoadingContainer = styled('div')`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
