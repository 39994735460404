import { round } from '../Math';

/**
 * Convert pre-processed spredsheet into JSON format
 * @workbook data retrieved from XLSX.read method
 */
const toJson = (workbook) => {
  const result = {};
  workbook.SheetNames.forEach((sheetName) => {
    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    if (roa.length) result[sheetName] = roa;
  });
  return JSON.stringify(result, 2, 2);
};

/**
 * Convert XLSX or XLS information into JSON format
 * @data data retrieved from excel file
 */
export const processExcel = (data) => {
  const workbook = XLSX.read(data, {
    type: 'binary',
  });
  const dataJson = toJson(workbook);
  return dataJson;
};

/**
 * Convert XLS/XLSX date data or string en-US into JS date object
 * @serial XLS/XLSX serial date or en-US date string
 */
export const excelDateToJSDate = (serial = null) => {
  if (!serial) return null;
  if (typeof serial === 'string') {
    const dateArr = serial.split('/');
    const dateStr = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
    return new Date(dateStr);
  }

  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);
  const date = new Date(
    dateInfo.getFullYear(),
    dateInfo.getMonth(),
    dateInfo.getDate()
  );
  date.setDate(date.getDate() + 1);

  return date;
};

/**
 * Remove country code, non-numeric characters from phone number
 * @phone string
 */
export const ajustPhoneNumber = (phone) => {
  let phoneNumber = `${phone}`;
  if (phoneNumber[0] === '0') phoneNumber = phoneNumber.substr(1);
  return (phoneNumber || '')
    .replace('+55', '')
    .replace(/\D/g, '')
    .replace('00000000000', '')
    .replace('11111111111', '')
    .replace('22222222222', '')
    .replace('33333333333', '')
    .replace('44444444444', '')
    .replace('55555555555', '')
    .replace('66666666666', '')
    .replace('77777777777', '')
    .replace('88888888888', '')
    .replace('99999999999', '');
};

/**
 * Parse customers file from Siagri system
 * @file object containing customers data
 * @userId string userId responsable for uploading the file
 * @name string name of the franchisor responsible for the customer
 * @phoneNumber string phoneNumber of the franchisor responsible for the customer
 */

export const parseCustomersFileFromSiagri = (
  file,
  userId,
  name,
  phoneNumber
) => {
  const uploadDate = new Date();
  const system = 'Siagri';
  try {
    // index 0 means that we are getting information from the first tab
    const Planilha = Object.values(file)[0];
    if (Planilha[0][0] !== 'Código do Parceiro') {
      throw new Error('Wrong file');
    }

    // excluding the first element witch is the header
    const withoutHeader = Planilha.slice(1);
    const preInformations = withoutHeader
      .filter((customer) => customer.length > 0)
      .reduce(
        (
          acc,
          [
            customerId,
            customerName,
            city,
            state,
            phone1,
            phone2,
            phone3,
            birthDate,
            spouse /* eslint-disable-line */,
            phone4,
            phone5,
            phone6,
            phone7,
            cultivation,
          ]
        ) => {
          const customerIdStr = `${customerId}`;

          const cultivationOption = {
            cultivationName: cultivation,
          };

          const thereAreCultivations = Array.isArray(
            acc[customerIdStr]?.phoneNumbers[0]?.cultivationList
          );

          const cultivationList =
            thereAreCultivations && cultivation
              ? [
                  ...acc[customerIdStr].phoneNumbers[0].cultivationList,
                  cultivationOption,
                ]
              : cultivation
              ? [cultivationOption]
              : [];

          const phoneNumber1 = ajustPhoneNumber(phone1);
          const phoneNumber2 = ajustPhoneNumber(phone2);
          const phoneNumber3 = ajustPhoneNumber(phone3);
          const phoneNumber4 = ajustPhoneNumber(phone4);
          const phoneNumber5 = ajustPhoneNumber(phone5);
          const phoneNumber6 = ajustPhoneNumber(phone6);
          const phoneNumber7 = ajustPhoneNumber(phone7);

          const phoneNumbers = [
            phoneNumber1,
            phoneNumber2,
            phoneNumber3,
            phoneNumber4,
            phoneNumber5,
            phoneNumber6,
            phoneNumber7,
          ];

          let cellphoneNumbers = phoneNumbers.filter(
            (phone) => phone && phone.length === 11
          );
          // Delete repeated cell phone numbers
          cellphoneNumbers = [...new Set(cellphoneNumbers)];

          cellphoneNumbers = cellphoneNumbers.map((cellphoneNumber) => ({
            phoneNumber: cellphoneNumber,
            city,
            state,
            birthDate: excelDateToJSDate(birthDate),
            nickname: customerName,
            ordersMessages: true,
            weatherMessages: false,
            dolarMessages: false,
            system,
            customerName,
            userId,
            franchiseeName: name,
            franchiseePhoneNumber: phoneNumber,
            customerId: customerIdStr,
            cultivationList,
          }));

          return {
            ...acc,
            [customerIdStr]: {
              userId,
              system,
              customerName,
              phoneNumbers: cellphoneNumbers,
              uploadDate,
            },
          };
        },
        {}
      );

    // Transform object into array of objects
    const informations = Object.entries(preInformations).map(
      ([customerId, rest]) => ({
        customerId,
        ...rest,
      })
    );

    return informations;
  } catch (e) {
    return e;
  }
};

/**
 * Parse customers file from SAP system
 * @file object containing customers data
 * @userId string userId responsable for uploading the file
 * @name string name of the franchisor responsible for the customer
 * @phoneNumber string phoneNumber of the franchisor responsible for the customer
 */
export const parseCustomersFileFromSAP = (file, userId, name, phoneNumber) => {
  const uploadDate = new Date();
  const system = 'SAP';
  try {
    const Planilha = Object.values(file)[0];
    // If the first column is not what is expected, throws an error
    if (Planilha[0][0] !== 'Código do PN') {
      throw new Error('Wrong file');
    }

    const withoutHeader = Planilha.slice(1);
    const preInformations = withoutHeader
      .filter((customer) => customer.length > 0)
      .reduce(
        (
          acc,
          [
            customerId,
            customerName,
            foreignName /* eslint-disable-line */,
            balance /* eslint-disable-line */,
            account /* eslint-disable-line */,
            phone1,
            phone2,
            phone3,
            cultivation,
          ]
        ) => {
          const customerIdStr = `${customerId}`;

          const cultivationOption = {
            cultivationName: cultivation,
          };

          const thereAreCultivations = Array.isArray(
            acc[customerIdStr]?.phoneNumbers[0]?.cultivationList
          );

          const cultivationList =
            thereAreCultivations && cultivation
              ? [
                  ...acc[customerIdStr].phoneNumbers[0].cultivationList,
                  cultivationOption,
                ]
              : cultivation
              ? [cultivationOption]
              : [];

          const phoneNumber1 = ajustPhoneNumber(`${phone1}`);
          const phoneNumber2 = ajustPhoneNumber(`${phone2}`);
          const phoneNumber3 = ajustPhoneNumber(`${phone3}`);
          const phoneNumbers = [phoneNumber1, phoneNumber2, phoneNumber3];
          let cellphoneNumbers = phoneNumbers.filter(
            (phone) => phone && phone.length === 11
          );
          // Delete repeated cell phone numbers
          cellphoneNumbers = [...new Set(cellphoneNumbers)];

          cellphoneNumbers = cellphoneNumbers.map((cellphoneNumber) => ({
            phoneNumber: cellphoneNumber,
            system,
            nickname: customerName,
            customerName,
            userId,
            franchiseeName: name,
            franchiseePhoneNumber: phoneNumber,
            customerId: customerIdStr,
            cultivationList,
          }));

          return {
            ...acc,
            [customerIdStr]: {
              userId,
              system,
              customerName,
              phoneNumbers: cellphoneNumbers,
              uploadDate,
            },
          };
        },
        {}
      );

    // Transform object into array of objects
    const informations = Object.entries(preInformations).map(
      ([customerId, rest]) => ({
        customerId,
        ...rest,
      })
    );

    return informations;
  } catch (e) {
    return e;
  }
};

/**
 * Parse customers file from AGROTITAN system
 * @file object containing customers data
 * @userId string userId responsable for uploading the file
 * @name string name of the franchisor responsible for the customer
 * @phoneNumber string phoneNumber of the franchisor responsible for the customer
 */
export const parseCustomersFileFromAgrotitan = (
  file,
  userId,
  name,
  phoneNumber
) => {
  const uploadDate = new Date();
  const system = 'AGROTITAN';
  try {
    // index 0 means that we are getting information from the first tab
    const Planilha = Object.values(file)[0];
    if (Planilha[2][0] !== 'COD CLI') {
      throw new Error('Wrong file');
    }

    // excluding the first element witch is the header
    const withoutHeader = Planilha.slice(3);
    const preInformations = withoutHeader
      .filter((customer) => customer.length > 0 && customer[0] !== 'COD CLI')
      .reduce(
        (
          acc,
          [
            customerId,
            customerName,
            emptySpace1 /* eslint-disable-line */,
            emptySpace2 /* eslint-disable-line */,
            emptySpace3 /* eslint-disable-line */,
            emptySpace4 /* eslint-disable-line */,
            emptySpace5 /* eslint-disable-line */,
            emptySpace6 /* eslint-disable-line */,
            emptySpace7 /* eslint-disable-line */,
            emptySpace8 /* eslint-disable-line */,
            phone1,
            emptySpace9 /* eslint-disable-line */,
            phone2,
            emptySpace10 /* eslint-disable-line */,
            emptySpace11 /* eslint-disable-line */,
            phone3,
            emptySpace12 /* eslint-disable-line */,
            cultivation,
          ]
        ) => {
          const customerIdStr = `${customerId}`;

          const cultivationOption = {
            cultivationName: cultivation,
          };

          const thereAreCultivations = Array.isArray(
            acc[customerIdStr]?.phoneNumbers[0]?.cultivationList
          );

          const cultivationList =
            thereAreCultivations && cultivation
              ? [
                  ...acc[customerIdStr].phoneNumbers[0].cultivationList,
                  cultivationOption,
                ]
              : cultivation
              ? [cultivationOption]
              : [];

          const phoneNumber1 = ajustPhoneNumber(phone1);
          const phoneNumber2 = ajustPhoneNumber(phone2);
          const phoneNumber3 = ajustPhoneNumber(phone3);
          const phoneNumbers = [phoneNumber1, phoneNumber2, phoneNumber3];

          let cellphoneNumbers = phoneNumbers.filter(
            (phone) => phone.length === 11
          );
          // Delete repeated cell phone numbers
          cellphoneNumbers = [...new Set(cellphoneNumbers)];

          cellphoneNumbers = cellphoneNumbers.map((cellphoneNumber) => ({
            phoneNumber: cellphoneNumber,
            system,
            nickname: customerName,
            customerName,
            userId,
            franchiseeName: name,
            franchiseePhoneNumber: phoneNumber,
            customerId: customerIdStr,
            cultivationList,
          }));

          return {
            ...acc,
            [customerIdStr]: {
              userId,
              system,
              customerName,
              phoneNumbers: cellphoneNumbers,
              uploadDate,
            },
          };
        },
        {}
      );

    // Transform object into array of objects
    const informations = Object.entries(preInformations).map(
      ([customerId, rest]) => ({
        customerId,
        ...rest,
      })
    );

    return informations;
  } catch (e) {
    return e;
  }
};

/**
 * Parse orders file from Siagri system
 * @file object containing orders data
 */
export const parseOrdersFileFromSiagri = (file) => {
  const uploadDate = new Date();
  try {
    const Planilha = Object.values(file)[0];
    if (Planilha[0][0] !== 'Código da loja') {
      throw new Error('Wrong file');
    }

    const withoutHeader = Planilha.slice(1);
    // Makes an object that contains all information about orders
    // grouped by order number.
    const preinformation = withoutHeader
      .filter((order) => order.length > 0)
      .reduce(
        (
          acc,
          [
            orderNumberPre,
            orderNumber,
            orderNumberPos,
            customerId,
            customerName,
            orderDate,
            orderExpirationDate /* eslint-disable-line */,
            codeitemCfop,
            pontualDiscount /* eslint-disable-line */,
            unityAbbreviation /* eslint-disable-line */,
            priceListCode /* eslint-disable-line */,
            pricePerUnit,
            obervation /* eslint-disable-line */,
            totalAmount,
            toBill,
            billed,
            commercialObservation /* eslint-disable-line */,
            USUName /* eslint-disable-line */,
            munipro /* eslint-disable-line */,
            propertyIE /* eslint-disable-line */,
            cnpjCpf /* eslint-disable-line */,
            phonePro /* eslint-disable-line */,
            regionDescription /* eslint-disable-line */,
            muncli /* eslint-disable-line */,
            lostQuantity /* eslint-disable-line */,
            routeDescription /* eslint-disable-line */,
            grossWeight /* eslint-disable-line */,
            netWeight /* eslint-disable-line */,
            name,
          ]
        ) => ({
          ...acc,
          [`${orderNumberPre}-${orderNumber}-${orderNumberPos}`]: acc[
            `${orderNumberPre}-${orderNumber}-${orderNumberPos}`
          ]
            ? {
                customerId: `${customerId}`,
                customerName,
                orderDate: excelDateToJSDate(orderDate),
                codeitemCfop: codeitemCfop.toString(),
                productList: [
                  ...acc[`${orderNumberPre}-${orderNumber}-${orderNumberPos}`]
                    .productList,
                  {
                    name,
                    totalAmount: round(totalAmount, 5),
                    pricePerUnit: round(pricePerUnit, 2),
                    toBill: round(toBill, 5),
                    billed: round(billed, 5),
                  },
                ],
                uploadDate,
              }
            : {
                customerId: `${customerId}`,
                customerName,
                codeitemCfop: codeitemCfop.toString(),
                orderDate: excelDateToJSDate(orderDate),
                productList: [
                  {
                    name,
                    totalAmount: round(totalAmount, 5),
                    pricePerUnit: round(pricePerUnit, 2),
                    toBill: round(toBill, 5),
                    billed: round(billed, 5),
                  },
                ],
                uploadDate,
              },
        }),
        {}
      );

    // Transform object into array of objects
    const information = Object.entries(preinformation)
      .map(([orderNumber, rest]) => ({
        orderNumber,
        ...rest,
      }))
      // Delete information abour advance-sale cathegory
      .filter(
        (obj) => obj.codeitemCfop !== '592200' && obj.codeitemCfop !== '692200'
      );

    const completeInformation = information.map((order) => {
      // Contains an array of product billed statuses
      let status = order.productList.map(
        (item) => item.totalAmount === item.billed
      );

      // Verify if some item has a not zero billed amout
      const billed = order.productList.map((item) => item.billed !== 0);

      status = status.every((item) => item)
        ? // if all itens are billed
          'Totalmente Faturado'
        : status.some((item) => item)
        ? // if some item are billed
          'Parcialmente Faturado'
        : billed.some((item) => item)
        ? // if some item has a billed amout different of zero
          'Parcialmente Faturado'
        : // None of above cases
          'Aguardando Faturamento';

      const totalPrice = order.productList
        .reduce((acc, item) => acc + item.pricePerUnit * item.totalAmount, 0)
        .toFixed(2);
      const formattedPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalPrice);
      return {
        ...order,
        status,
        totalPrice: formattedPrice,
      };
    });

    return completeInformation;
  } catch (e) {
    return e;
  }
};

/**
 * Parse orders file from SAP system
 * @file object containing orders data
 */
export const parseOrdersFileFromSAP = (file) => {
  const uploadDate = new Date();
  try {
    const Planilha = Object.values(file)[0];
    if (Planilha[0][0] !== '#') {
      throw new Error('Wrong file');
    }

    const withoutHeader = Planilha.slice(1);
    const preinformation = withoutHeader
      .filter((order) => order.length > 0)
      .reduce(
        (
          acc,
          [
            lineNumber /* eslint-disable-line */,
            customerId,
            customerName,
            orderNumber,
            itemNumber /* eslint-disable-line */,
            name,
            totalAmount,
            totalPrice,
            orderDate,
            status,
            typeDoc,
          ]
        ) => ({
          ...acc,
          [orderNumber]: acc[orderNumber]
            ? {
                customerId: `${customerId}`,
                customerName,
                orderDate: excelDateToJSDate(orderDate),
                productList: [
                  ...acc[orderNumber].productList,
                  {
                    name,
                    totalAmount,
                    totalPrice,
                    status, // C === "Close", O === "Open"
                    billingDate: status === 'C' ? new Date() : null,
                  },
                ],
                uploadDate,
                typeDoc: typeDoc === undefined ? null : typeDoc,
              }
            : {
                customerId: `${customerId}`,
                customerName,
                orderDate: excelDateToJSDate(orderDate),
                productList: [
                  {
                    name,
                    totalAmount,
                    totalPrice,
                    status,
                    billingDate: status === 'C' ? new Date() : null,
                  },
                ],
                uploadDate,
                typeDoc: typeDoc === undefined ? null : typeDoc,
              },
        }),
        {}
      );

    const information = Object.entries(preinformation).map(
      ([orderNumber, rest]) => ({
        orderNumber,
        ...rest,
      })
    );

    const completeInformation = information.map((order) => {
      let status = order.productList.map((item) => item.status === 'C');
      status = status.every((item) => item)
        ? 'Totalmente Faturado'
        : status.some((item) => item)
        ? 'Parcialmente Faturado'
        : 'Aguardando Faturamento';
      const totalPrice = order.productList
        .reduce((acc, item) => acc + item.totalPrice, 0)
        .toFixed(2);
      const formattedPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalPrice);

      return {
        ...order,
        status,
        totalPrice: formattedPrice,
      };
    });

    return completeInformation;
  } catch (e) {
    return e;
  }
};

/**
 * Parse orders file from AGROTITAN system
 * @file object containing orders data
 */
export const parseOrdersFileFromAgrotitan = (file) => {
  const uploadDate = new Date();
  try {
    const Planilha = Object.values(file)[0];
    if (Planilha[1][0] !== 'Cod Cli') {
      throw new Error('Wrong file');
    }

    const withoutHeader = Planilha.slice(1);
    const preinformation = withoutHeader
      .filter((order) => order.length > 0 && order[0] !== 'Cod Cli')
      .filter((order) => order.length > 0)
      .reduce(
        (
          acc,
          [
            customerId,
            customerName,
            emptySpace1 /* eslint-disable-line */,
            emptySpace2 /* eslint-disable-line */,
            emptySpace3 /* eslint-disable-line */,
            emptySpace4 /* eslint-disable-line */,
            emptySpace5 /* eslint-disable-line */,
            emptySpace6 /* eslint-disable-line */,
            emptySpace7 /* eslint-disable-line */,
            emptySpace8 /* eslint-disable-line */,
            orderNumber,
            emptySpace9 /* eslint-disable-line */,
            name,
            emptySpace10 /* eslint-disable-line */,
            emptySpace11 /* eslint-disable-line */,
            totalAmount,
            emptySpace12 /* eslint-disable-line */,
            totalPrice,
            emptySpace13 /* eslint-disable-line */,
            emptySpace14 /* eslint-disable-line */,
            orderDate,
            emptySpace15 /* eslint-disable-line */,
            status,
          ]
        ) => ({
          ...acc,
          [orderNumber]: acc[orderNumber]
            ? {
                customerId: `${customerId}`,
                customerName,
                orderDate: excelDateToJSDate(orderDate),
                productList: [
                  ...acc[orderNumber].productList,
                  {
                    name,
                    totalAmount,
                    totalPrice,
                    status,
                  },
                ],
                uploadDate,
              }
            : {
                customerId: `${customerId}`,
                customerName,
                orderDate: excelDateToJSDate(orderDate),
                productList: [
                  {
                    name,
                    totalAmount,
                    totalPrice,
                    status,
                  },
                ],
                uploadDate,
              },
        }),
        {}
      );

    const information = Object.entries(preinformation).map(
      ([orderNumber, rest]) => ({
        orderNumber,
        ...rest,
      })
    );

    const completeInformation = information.map((order) => {
      let status = order.productList.map((item) => item.status === 'BAIXADO');
      status = status.every((item) => item)
        ? 'Totalmente Faturado'
        : status.some((item) => item)
        ? 'Parcialmente Faturado'
        : 'Aguardando Faturamento';
      const totalPrice = order.productList
        .reduce((acc, item) => acc + item.totalPrice, 0)
        .toFixed(2);
      const formattedPrice = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalPrice);

      return {
        ...order,
        status,
        totalPrice: formattedPrice,
      };
    });

    return completeInformation;
  } catch (e) {
    return e;
  }
};
