import {
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
  EmailAuthProvider,
} from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import { User } from '../../../DTOs';
import { appendError } from '../../storage';
import { deleteUser /* updateRTVPassword */ } from '../../cloudFunctions';
import firebaseApp, { secondaryApp } from '../../firebase';
import { makeQuery } from '../AuxFunctions';

/** Firestore reference variable */
const db = getFirestore(firebaseApp);
const timestamp = Timestamp.fromDate(new Date());

// Create a reges for a strong password:
//
// - One upper and lower case
// - One number
// - One special character
// - At least six digits
const strongRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
);
const weakPasswordError = new Error(
  'Senha fraca. A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número, um caracter especial e 6 dígitos.'
);
weakPasswordError.code = 'weak-password';

/** Create an user on firebase credentials
 * @param {string} email
 * @param {string} password
 */
export const createUserCredentials = (email, password) =>
  new Promise((resolve, reject) => {
    const auth = getAuth(secondaryApp);
    if (!strongRegex.test(password)) {
      reject(weakPasswordError);
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((successObj) => {
        signOut(auth)
          .then(() => {
            resolve(successObj);
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });

/** Update user password
 * @param {string} password
 */
export const updateUserPassword = (password) => {
  if (!strongRegex.test(password)) {
    throw weakPasswordError;
  }
  const auth = getAuth(firebaseApp);
  const user = auth.currentUser;

  return updatePassword(user, password);
};

// export const updateRTVUserPassword = (uid, pass) => updateRTVPassword(uid, pass);

/** Re-authenticate user using a provided email and password
 * @param {Object} credential - User credentials
 */
export const reauthenticateUser = (credential) => {
  const auth = getAuth(firebaseApp);
  const user = auth.currentUser;
  const firebaseCredential = EmailAuthProvider.credential(
    credential.email,
    credential.password
  );
  return reauthenticateWithCredential(user, firebaseCredential);
};

/** Create an user entry on database
 * @param {Object} userData
 */
export const createUserData = (userData) => {
  try {
    const userObj = new User(userData);
    return setDoc(doc(db, 'users', userData.userId), userObj.getObject);
  } catch (err) {
    appendError({
      path: 'src/services/dbFunctions/Users/index.js',
      message: err.message,
    });
    console.log(err);
    return null;
  }
};

/** Gets the data from an specific user
 * @param {string} userId
 */
export const getUserData = (userId) =>
  getDoc(doc(db, 'users', userId)).then((docSnap) => {
    if (docSnap.exists()) {
      return { ...docSnap.data(), userId: docSnap.id };
    }
    return null;
  });

/** Gets all users data */
export const getAllUsers = () =>
  getDocs(collection(db, 'users')).then((querySnapshot) =>
    querySnapshot.map((docSnap) => ({ ...docSnap.data(), userId: docSnap.id }))
  );

/** Get paginated users. Returns an object with an array of the users data
 * and an parameter 'nextQuery', which can be passed as a parameter to the
 * next call of this function, to give the next page.
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedUsers = (
  orderByToMake,
  queryToMake = query(
    collection(db, 'users'),
    where('isRTV', '==', false),
    orderBy('email'),
    orderBy(orderByToMake),
    limit(10)
  )
) => makeQuery(queryToMake, 'userId').then((result) => result);

/** Get paginated RTV users. Returns an object with an array of the users data
 * and an parameter 'nextQuery', which can be passed as a parameter to the
 * next call of this function, to give the next page.
 * @param {string} orderByToMake - Name of the parameter to order the results from
 * @param {Object} queryToMake - Can be omitted if it is the first page
 */
export const getPaginatedRTVUsers = (
  orderByToMake,
  queryToMake = query(
    collection(db, 'users'),
    where('isRTV', '==', true),
    orderBy('email'),
    orderBy(orderByToMake),
    limit(10)
  )
) =>
  makeQuery(queryToMake, 'userId')
    .then((result) => result)
    .catch((err) => console.log(err));

// Get all rtv's by franchise paginated
export const getPaginatedRTVUsersFromFranchise = (
  userId,
  userName,
  orderByToMake,
  queryToMake = query(
    collection(db, 'users'),
    where('isRTV', '==', true),
    where('franchisees', 'array-contains', { id: userId, label: userName }),
    orderBy('email'),
    orderBy(orderByToMake),
    limit(10)
  )
) =>
  makeQuery(queryToMake, 'userId')
    .then((result) => result)
    .catch((err) => console.log(err));

// Get all franchises users
export const getAllFranchiseUsers = async () => {
  const franchisesUsers = query(
    collection(db, 'users'),
    where('isRTV', '==', false)
  );
  const querySnapshot = await getDocs(franchisesUsers);
  return querySnapshot.docs.map((docSnap) => docSnap.data());
};

export const getRTVUsersFromFranchisee = async (userId, userName) => {
  // const docs = [];
  const rtvUsers = query(
    collection(db, 'users'),
    where('isRTV', '==', true),
    where('franchisees', 'array-contains', { id: userId, label: userName })
  );
  const querySnap = await getDocs(rtvUsers);
  return querySnap.docs.map((docSnap) => docSnap.data());
};

/** Update user data.
 * @param {string} userId
 * @param {Object} userData - Contains only the parameters to be updated.
 */
export const updateUserData = (userId, userData) =>
  updateDoc(doc(db, 'users', userId), userData);

/** Delete user data from database
 * @param {string} userId
 */
export const deleteUserData = (userId) =>
  deleteDoc(doc(db, 'users', userId))
    .then(() => deleteUser(userId))
    .catch((error) => {
      appendError({
        path: 'src/services/dbFunctions/Users/index.js',
        message: err.message,
      });
      console.log(error);
    });

export const lastUpdateUser = (userId) =>
  updateDoc(doc(db, 'users', userId), {
    lastUpdate: timestamp,
  });

/**
 * Gets all the customers of RTV user's franchisees
 * @param {Array<object>} rtvFranchisees - Array with the list of the customers of RTV user's franchisees
 */
export const getAllCustomersFromRtvFranchisees = async (rtvFranchisees) => {
  // Gets all customers from all the rtvFranchisees
  const customers = await Promise.all(
    rtvFranchisees.map((franchisee) =>
      getDocs(collection(db, `users/${franchisee.id}/customers`))
    )
  );

  // Combines everything to an array
  const allCustomers = [];
  customers.forEach((customerArray) => {
    customerArray.forEach((snap) =>
      allCustomers.push({ ...snap.data(), customerId: snap.id })
    );
  });

  return allCustomers;
};
