function productListFormat(product) {
  return {
    name: product.name || null,
    totalAmount: product.totalAmount || null,
    status: product.status || null,
    totalPrice: product.totalPrice || null,
    billingDate: product.billingDate || null,
    billed: product.billed || null,
    toBill: product.toBill || null,
    pricePerUnit: product.pricePerUnit || null,
  };
}
class Order {
  constructor(orderObject) {
    const {
      customerId,
      customerName,
      orderNumber,
      orderDate,
      status,
      productList,
      totalPrice,
      typeDoc,
      uploadDate,
      codeitemCfop,
    } = orderObject;

    if (!customerId || !orderNumber || !status)
      throw new Error('Undefined customerId, orderNumber or status');

    this.customerId = customerId;
    this.status = status;
    this.orderNumber = orderNumber;

    // Reinforces a format for the product list
    this.productList = productList
      ? productList.map(productListFormat)
      : [
          {
            name: null,
            totalAmount: null,
            status: null,
            totalPrice: null,
            billingDate: null,
            billed: null,
            toBill: null,
            pricePerUnit: null,
          },
        ];

    this.codeitemCfop = codeitemCfop || null;
    this.customerName = customerName || null;
    this.orderDate = orderDate || new Date();
    this.totalPrice = totalPrice || null;
    this.typeDoc = typeDoc || null;
    this.uploadDate = uploadDate || new Date();
  }

  get getObject() {
    return {
      codeitemCfop: this.codeitemCfop,
      customerId: this.customerId,
      customerName: this.customerName,
      orderNumber: this.orderNumber,
      orderDate: this.orderDate,
      status: this.status,
      productList: this.productList,
      totalPrice: this.totalPrice,
      typeDoc: this.typeDoc,
      uploadDate: this.uploadDate,
    };
  }
}

export default Order;
