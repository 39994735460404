import {
  Box,
  Divider,
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

// eslint-disable-next-line import/no-unresolved
import SandwichIcon from 'Assets/drawer/sandwich.svg';
// eslint-disable-next-line import/no-unresolved
import UserIcon from 'Assets/drawer/user.svg';
// eslint-disable-next-line import/no-unresolved
import SignOut from 'Assets/drawer/signout.svg';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '@/Global/Modules/Login/Redux/Actions';
import { IconButton, Toolbar, UserName, Footer } from './Drawer_style';

const drawerWidth = '90%';

const menuOptions = [
  {
    title: 'Perfil',
    link: '/myprofile',
  },
];

const Drawer = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    dispatch(actions.signOutAction());
  };

  return (
    <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
      <IconButton onClick={handleDrawerToggle}>
        <img src={SandwichIcon} alt="Menu" />
      </IconButton>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <MUIDrawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: open ? drawerWidth : 'none',
          },
        }}
      >
        <div>
          <Toolbar>
            <img src={UserIcon} alt="Usuário" />
            <UserName>Consultor</UserName>
          </Toolbar>
          <Divider />
          <List>
            {menuOptions.map((option) => (
              <ListItem
                button
                key={option.title}
                onClick={() => {
                  handleDrawerToggle();
                  history.push(option.link);
                }}
              >
                <ListItemText primary={option.title} />
              </ListItem>
            ))}
          </List>
        </div>
        <Footer>
          <List>
            <ListItem
              button
              onClick={() => {
                handleClose();
              }}
            >
              <ListItemIcon>
                <img src={SignOut} alt="Sair" />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItem>
          </List>
        </Footer>
      </MUIDrawer>
    </Box>
  );
};

export default Drawer;
