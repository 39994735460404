import { getDocs, query, startAfter } from 'firebase/firestore';

/** Aux function that makes a paginated query
 * @param {Object} queryToMake - Query to be made by this function
 * @param {string} idParameterName - Name of the id parameter
 */
export const makeQuery = (queryToMake, idParameterName) =>
  getDocs(queryToMake).then((documentSnapshots) => {
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    let nextQuery;
    if (lastVisible) {
      nextQuery = query(queryToMake, startAfter(lastVisible));
    } else {
      nextQuery = null;
    }

    const docs = documentSnapshots.docs.map((docSnap) => ({
      ...docSnap.data(),
      [idParameterName]: docSnap.id,
    }));

    return { docs, nextQuery };
  });
