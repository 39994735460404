import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import styled from 'styled-components';
import { GlobalColors, GlobalFontStyle } from './GlobalStyles';

const StyledTheme = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
  a {
    color: ${GlobalColors.Primary.primaryBlue};
    text-decoration: none;
    font-size: 10px;
  }
`;

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  // Main color on palette
  palette: {
    primary: { main: GlobalColors.Primary.verdePrimario },
    secondary: { main: GlobalColors.Primary.azulPrimario },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedPrimary: {
        color: GlobalColors.SupportColors.whiteLight,
      },
      label: {
        font: GlobalFontStyle.H3.font,
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        font: GlobalFontStyle.H6.font,
      },
    },
  },
});

export const breakpoints = theme.breakpoints.values;

const GlobalTheme = ({ children }) => (
  <StyledTheme>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyledTheme>
);

export default GlobalTheme;
